"use strict";

import _ from "underscore";

const searchbarToggles = document.getElementsByClassName("searchbar--toggle");

_.each(searchbarToggles, bar => {
    let trigger = bar.getElementsByClassName("searchbar__trigger")[0];
    trigger.style.cursor = "pointer";
    trigger.onclick = () => {
        $(bar).toggleClass("expanded");

        if ($(bar).hasClass("expanded")) {
            // Defer focus so swiftype search autocomplete dropdown renders only after searchbar fuly expanded
            // to fix misaligned positioning of autocomplete box
            setTimeout(function () {
                $(bar).find("input")[0].focus();
            }, 250);
        }
    };
});

