"use strict";
import jump from "jump.js";
import _ from "underscore";

window.addEventListener('DOMContentLoaded', function(){

	let mainNavLinks = document.getElementsByClassName("side-nav-items")[0];
	let mainSections = document.querySelectorAll("div.sidenav-main section");

	if (mainNavLinks) {
		const easeInOutCubic = function(t, b, c, d) {
		    if ((t /= d / 2) < 1) return c / 2 * t * t * t + b;
		    return c / 2 * ((t -= 2) * t * t + 2) + b;
		};

		let ul = document.createElement("ul");

		_.each(mainSections, function(link) {
			let li = document.createElement("li");
			let a = document.createElement("a");
			a.innerHTML = link.getAttribute("data-scroll-target");
			a.onclick = function(e) {
				let section = document.getElementById(link.id);
				console.log(section);
		        jump(section, {
		        	offset: -100,
		            duration: 1000,
		            easing: easeInOutCubic
		        });
			}
			li.appendChild(a);
			mainNavLinks.appendChild(li);
		});

		let navTop = 0;
		let lastScrollTop = 0;

		$(window).scroll(function() {
			let sideNavContainer = document.getElementsByClassName("sidenav-container")[0].getBoundingClientRect();
			let mainNavLinksContainer = mainNavLinks.getBoundingClientRect();

			var st = window.pageYOffset || document.documentElement.scrollTop;

			if(sideNavContainer.top < ((mainNavLinksContainer.height - sideNavContainer.height)) && !mainNavLinks.classList.contains("bottom") && mainNavLinks.classList.contains("going-down")){
				navTop = sideNavContainer.top;
				mainNavLinks.classList.remove("going-down");
				mainNavLinks.classList.add("bottom");
			} else if(sideNavContainer.top > navTop + 100 && mainNavLinks.classList.contains("bottom")) {
				mainNavLinks.classList.remove("bottom");
			}


			//We are scrolled below the top elements
			if(sideNavContainer.top < 0) {
				mainNavLinks.classList.remove("top");
				if (sideNavContainer.top < 10 && !mainNavLinks.classList.contains("bottom")) {
					mainNavLinks.classList.add("going-down");
				}
				if (st > lastScrollTop && !mainNavLinks.classList.contains("bottom")){
					mainNavLinks.classList.remove("going-up");
					mainNavLinks.classList.add("going-down");
				} else {
					if (sideNavContainer.top > ((mainNavLinksContainer.height - sideNavContainer.height) + 100) && !(mainNavLinks.classList.contains("bottom"))) {
						mainNavLinks.classList.remove("going-down");
						mainNavLinks.classList.add("going-up");
					}
				}
			} else if(sideNavContainer.top > 100){
				mainNavLinks.classList.remove("going-up");
				mainNavLinks.classList.add("top");
			}

			lastScrollTop = st <= 0 ? 0 : st;

		});

	}

});


