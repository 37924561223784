
import slick from "../slick.js";

//testimonial carousel init
$(window).on("load", function() {
    $(".logo-carousel").slick({
        arrows: false,
        pauseOnOver: false,
        pauseOnFocus: false,
        accessibility: false,
        draggable: false,
        swipe: false,
        touchMove: false,
        waitForAnimate: false,

        autoplay: true,
        autoplaySpeed: 0,
        speed: 6000,
        centerMode: true,
        infinite: true,
        slidesToScroll: 1,
        slidesToShow: 3,
        cssEase: "linear",
        variableWidth: true
    });
});

//const scrollAnimations init
window.toggleClass = function(className, target) {
    $(target).toggleClass(className);
};
