
import slick from "../slick.js";

//testimonial carousel init
$(document).ready(function() {
    $(".testimonial-carousel").slick({
        autoplay: true,
        autoplaySpeed: 3000,
        centerMode: false,
        arrows: false,
        pauseOnOver: false,
        pauseOnFocus: false
    });
});
