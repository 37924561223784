"use strict";
import _ from "underscore";
import ModalToggle from "../utils/ModalToggle.js";

const directionList = {
    UP: "up",
    DOWN: "down"
};

const positionList = {
    RELATIVE: "relative",
    FIXED: "fixed"
};

const navClassNames = {
    RELATIVE: "navbar--relative",
    FIXED: "navbar--fixed",
    MINIMIZED: "navbar--minimized",
    MAXIMIZED: "navbar--maximized"
};

class NavMenu {
    constructor(li) {
        this.li = li;
        this._toggle = this._toggle.bind(this);
        this.trigger = li.getElementsByTagName("a")[0] || li;
        this.trigger.onclick = this._toggle;
    }
    _toggle(e) {
        if (e.target !== this.trigger) {
            return;
        }
        $(this.li).toggleClass("expanded");
    }
}

class MobileButton {
    constructor(nav, button) {
        this.nav = nav;
        this._toggle = this._toggle.bind(this);
        this._clickListener = this._clickListener.bind(this);
        this._didClickOutside = this._didClickOutside.bind(this);
        this.button = button;
        this.open = false;
        this.button.onclick = this._toggle;
        this.onClick = null;
    }
    _toggle() {
        this.open = !this.open;
        $(this.nav).toggleClass("mobile-nav--open");

        if (this.open) {
            ModalToggle.open();
            window.addEventListener("click", this._clickListener);
        }
        else {
            ModalToggle.close();
            window.removeEventListener("click", this._clickListener);
            $(".expanded").removeClass("expanded");
        }
    }
    _clickListener(e) {
        if (e.target.id === "topNav") {
            this._toggle();
        }
    }
    _didClickOutside(el) {
        if (el === this.nav) {
            return false;
        }
        if (el === document.documentElement) {
            return true;
        }
        return this._didClickOutside(el.parentNode);
    }
}

class Nav {
    constructor(nav, opts = {}) {
        this._handleCollapsibleMenu = this._handleCollapsibleMenu.bind(this);
        this._handleDropdownMenu = this._handleDropdownMenu.bind(this);
        this._handlePositionChange = this._handlePositionChange.bind(this);
        this._getNewPosition = this._getNewPosition.bind(this);
        this._isInZone = this._isInZone.bind(this);
        this._setState = this._setState.bind(this);
        this._shouldUpdate = this._shouldUpdate.bind(this);
        this._updateDOM = this._updateDOM.bind(this);

        let scrollPos = $(window).scrollTop();
        this.nav = nav;
        let toggle = nav.getElementsByClassName("top-nav__toggle")[0] || null;

        this._handleCollapsibleMenu();
        this._handleDropdownMenu();
        this.toggle = toggle && new MobileButton(this.nav, toggle);
        this.navRect = {
            top: this.nav.getBoundingClientRect().top + scrollPos,
            bottom: this.nav.getBoundingClientRect().bottom + scrollPos
        };

        this.onScroll = this.onScroll.bind(this);

        let position = this._getNewPosition(null, scrollPos);
        this.options = _.defaults(opts, {
            pixelOffset: 30,
            direction: null,
            position: position,
            navClassNames: navClassNames,
            onScroll: _.noop,
            onPositionChange: _.noop,
            onDirectionChange: _.noop,
            scrollThrottle: 100,
            addSpacer: true
        });

        if (this.options.addSpacer) {
            this.spacer = this.nav.cloneNode();
            this.spacer.id = this.nav.id + "Spacer";
            this.spacer.style.visibility = "hidden";
            this.spacer.style.display = "none";
            $(this.spacer).addClass("nav__spacer");
            $(this.spacer).insertAfter(this.nav);
        }
        this.pixelOffset = this.options.pixelOffset;
        this.navClassNames = this.options.navClassNames;

        let classNames = position === positionList.RELATIVE
            ? [this.navClassNames.RELATIVE]
            : [this.navClassNames.MINIMIZED, this.navClassNames.FIXED];
            
        if (location.hash !== "") {
        		classNames = [this.navClassNames.MINIMIZED, this.navClassNames.FIXED];
        }

        let spacerDisplay = null;

        if (this.spacer) {
            spacerDisplay = position === positionList.FIXED ? "block" : "none";
        }

        this.initialState = {
            direction: this.options.direction,
            lastScroll: scrollPos,
            position: this.options.position,
            maximized: position === positionList.FIXED ? false : null,
            prevClassNames: null,
            classNames: classNames,
            spacerDisplay: spacerDisplay
            // navHeight: position === positionList.FIXED ? this.nav.offsetHeight + "px" : "auto"
            //navHeight: "auto"
        };
        this._setState(this.initialState);
        window.addEventListener(
            "scroll",
            _.throttle(this.onScroll, this.options.scrollThrottle)
        );
        
        this.options.onLoad && this.options.onLoad(this.state);
    }

    _handleCollapsibleMenu() {
        _.each(
            this.nav.getElementsByClassName("navbar__collapsed-menu"),
            title => {
                new NavMenu(title);
            }
        );
    }

    _handleDropdownMenu() {
        _.each(this.nav.getElementsByClassName("navbar__dropdown"), title => {
            new NavMenu(title);
        });
    }

    _handlePositionChange(pos, prev, next) {
        this.options.onPositionChange(pos, prev, next);
    }

    _getNewPosition(direction, windowScrollTop) {
        if (
            this.state &&
            this.state.position === positionList.RELATIVE &&
            this._isInZone(windowScrollTop)
        ) {
            return positionList.RELATIVE;
        }
        //Keep from jumping from fixed to relative on the upscroll
        let compareParam = direction === directionList.UP
            ? this.navRect.top
            : this.navRect.bottom;
        return windowScrollTop <= compareParam
            ? positionList.RELATIVE
            : positionList.FIXED;
    }

    _isInZone(windowScrollTop) {
        return windowScrollTop >= this.navRect.top &&
            windowScrollTop <= this.navRect.bottom;
    }

    onScroll() {
        if ($(document.body).hasClass("modal--open")) {
            return;
        }
        //onscroll fix for topnav when open. need a better future fix
        if ($("#topNav")) {
            if ($("#topNav").is(":hover")) {
                return;
            }
        }

        let newScroll = $(window).scrollTop();
        //Do nothing if pixelOffset isn't reached
        if (Math.abs(newScroll - this.state.lastScroll) < this.pixelOffset) {
            return;
        }
        let newState = _.clone(this.state);

        let direction = newScroll >= this.state.lastScroll
            ? directionList.DOWN
            : directionList.UP;
        let position = this._getNewPosition(direction, newScroll);
        //If the direction and position are the same,
        if (
            direction === this.state.direction &&
            position === this.state.position
        ) {
            newState.lastScroll = newScroll;
            this.options.onScroll(newState, this.state);
            this._setState(newState);
            return;
        }
        let newClassNames = [];
        if (position === positionList.RELATIVE) {
            newClassNames.push(this.navClassNames.RELATIVE);
            //newState.navHeight = "auto";
            newState.maximized = null;
        }
        else {
            newClassNames.push(this.navClassNames.FIXED);
            //newState.navHeight = this.nav.offsetHeight + "px";
            if (direction === directionList.DOWN) {
                newState.maximized = false;
                newClassNames.push(this.navClassNames.MINIMIZED);
            }
            else {
                newState.maximized = true;
                newClassNames.push(this.navClassNames.MAXIMIZED);
            }
        }

        newState.lastScroll = newScroll;
        newState.position = position;
        newState.direction = direction;
        newState.prevClassNames = this.state.classNames;
        newState.classNames = newClassNames;

        //get the spacer
        if (this.spacer) {
            newState.spacerDisplay = position === positionList.FIXED
                ? "block"
                : "none";
        }
        //send the callbacks
        position !== this.state.position &&
            this._handlePositionChange(position, this.state, newState);
        direction !== this.state.direction &&
            this.options.onDirectionChange(direction, this.state, newState);
        this.options.onScroll(this.state, newState);

        this._setState(newState);
    }
    _setState(newState) {
        let should = this.state
            ? this._shouldUpdate(this.state, newState)
            : true;
        this.state = newState;
        should && this._updateDOM();
    }
    _shouldUpdate(prev, next) {
        return !_.isEqual(prev.classNames, next.classNames);
    }
    _updateDOM() {
        _.each(this.state.prevClassNames, name => {
            $(this.nav).removeClass(name);
        });

        _.each(this.state.classNames, name => {
            $(this.nav).addClass(name);
        });

        //update spacer
        if (this.spacer) {
            this.spacer.style.display = this.state.spacerDisplay;
        }
        //this.nav.style.height = this.state.navHeight;
    }
}

export {Nav, navClassNames, positionList, directionList};
