"use strict";
import _ from "underscore";
import shave from 'shave';
import Promise from "bluebird";
import { callIfOutsideOfContainer } from "../utils/EventUtils.js";

Promise.config({
    // Enable cancellation
    cancellation: true,
    longStackTraces: true,
    warnings: true
});

class GroupedSearchbarResultRow {
    constructor (filterId, filterTagId, rowDOM, searchPath, language, maxResultsPerLoad, order) {
        // Bind necessary DOM elements to class
        this.node = rowDOM;
        this.loadMore = document.getElementById(filterTagId + "__loadMore");
        this.spinnerContainer = document.getElementById(filterTagId + "__spinnerContainer");
        this.resultsContainer = document.getElementById(filterTagId + "__resultsContainer");

        // Constants
        this.FILTER_ID = filterId;
        this.FILTER_TAG_ID = filterTagId;
        this.SEARCH_PATH = searchPath;
        this.LANGUAGE = language;
        this.MAX_RESULTS_PER_LOAD = maxResultsPerLoad;
        this.ORDER = order;

        // Initialize dynamic behavior for loadMore w/ the GroupedSearchbarResultRow
        this.dynamicizeLoadMore(this.loadMore);

        // Set initial state
        let initialState = {
            keywords: "",
            filterTags: {},
            offset: null,
            searchPromises: []
        };
        this.state = initialState;
    }

    dynamicizeLoadMore (loadMoreDOM) {
        if (!loadMoreDOM) {
            return;
        }

        loadMoreDOM.onclick = (e) => {
            this.hideElement("resultsContainer");
            this.showElement("spinnerContainer");

            // Reset offset if previous filter trigger was not the load more button
            if (this.state.offset === null) {
                this.state.offset = 1;
            } else {
                this.state.offset = this.state.offset + 1;
            }
            // Protect against quick consecutive clicks to change filter
            this.doSearchWithConsecutiveRequestManagement().then(() => {
                this.hideElement("spinnerContainer");
                this.showElement("resultsContainer");

                // Shave off results cards content to restricted height
                shave(".card--resource-card__heading", 50);
                shave(".card--resource-card__body", 100);
            });
        }
    }

    getCSRFToken () {
        return new Promise((resolve, reject, onCancel) => {
            let xhr = new XMLHttpRequest();
            xhr.onload = () => resolve(xhr);
            xhr.onerror = () => reject(xhr);
            xhr.open("GET", "/libs/granite/csrf/token.json", true);
            xhr.send(null);
            onCancel(() => {
                xhr.abort();
            });
        });
    }

    search (query, token) {
        // Use Bluebird Promise for cancel support
        return new Promise((resolve, reject, onCancel) => {
            let xhr = new XMLHttpRequest();
            xhr.onload = () => resolve(xhr);
            xhr.onerror = () => reject(xhr);
            xhr.open("GET", this.SEARCH_PATH + "?" + query, true);
            xhr.setRequestHeader("CSRF-Token", token);
            xhr.send();
            onCancel(() => {
                xhr.abort();
            });
        });
    }

    buildQuery (keywords, filterTags, offset) {
        const params = {
            keywords: keywords,
            language: this.LANGUAGE,

            // These work w/ load more
            limit: this.MAX_RESULTS_PER_LOAD,
            offset: offset || 0,
            order: this.ORDER
        };

        // Map 'filters{n}' -> 'tags{n}' for passing up to servlet params
        // Handle 'groupBy' filter
        if (!this.FILTER_ID) {
            for (let filterId in filterTags) {
                let n = filterId.substr(filterId.length - 1);
                params["tags" + n] = filterTags[filterId].join(",");
            }
        } else {
            for (let filterId in filterTags) {
                let n = filterId.substr(filterId.length - 1);

                if (filterId === this.FILTER_ID) {
                    params["tags" + n] = this.FILTER_TAG_ID;
                } else {
                    params["tags" + n] = filterTags[filterId].join(",");
                }
            }
        }

        const esc = encodeURIComponent;
        return Object.keys(params)
            .map(k => esc(k) + "=" + esc(params[k]))
            .join("&");
    }

    doSearch () {
        return this.getCSRFToken()
            .then(response => {
                if(response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                const msg = JSON.parse(response.responseText);
                let query = this.buildQuery(this.state.keywords, this.state.filterTags, this.state.offset * this.MAX_RESULTS_PER_LOAD);
                return this.search(query, msg.token);
            })
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return JSON.parse(response.responseText);
            })
            .then(data => {
                this.state.searchResponse = data;
            })
            .catch((e) => {
                throw new Error(e);
            })
            .finally(() => {
                this.render(
                    this.state.searchResponse.results,
                    this.state.searchResponse.resultsTotal
                );
            });
    }

    doSearchWithConsecutiveRequestManagement () {
        // Manage filter request promises to only fire off the last request if user
        //  quickly triggers multiple filters consecutively
        this.state.searchPromises.forEach((p) => {
            p.cancel();
        });

        this.state.searchPromises = [];
        let newPromise = this.doSearch();

        newPromise.then(() => {
            this.state.searchPromises.splice(this.state.searchPromises.indexOf(newPromise), 1);
        });
        this.state.searchPromises.push(newPromise);
        return newPromise;
    }

    toggleLoadMore (resultsTotal) {
        if (((this.state.offset + 1) * this.MAX_RESULTS_PER_LOAD) < resultsTotal) {
            this.showElement("loadMore");
        } else {
            this.hideElement("loadMore");
        }
    }

    render (results, resultsTotal) {
        // Render results or hide row if no results match for it
        if (results.length === 0) {
            this.hideElement("node");
        } else {
            let frag = document.createDocumentFragment();
            results.forEach((r) => {
                $(frag).append($.parseHTML($.parseHTML(r.resultMarkup, true)[0].data));
            });
            $(this.resultsContainer).append(frag);
            this.showElement("node");
        }
        // Show load more button when there are more results to be shown
        this.toggleLoadMore(resultsTotal);
    }

    resetOffset () {
        this.state.offset = null;
    }

    resetResultsContainer () {
        $(this.resultsContainer).empty();
    }

    getResults (keywords, filterTags) {
        // Only update results if row's groupBy filter tagID is included in the new filterTags list
        // o/w hide the row & skip update
        if (filterTags[this.FILTER_ID].indexOf(this.FILTER_TAG_ID) === -1) {
            this.hideElement("node");
            return new Promise((resolve, reject, cancel) => { resolve(); });
        } else {
            this.resetOffset();
            this.resetResultsContainer();
            this.state.keywords = keywords;
            this.state.filterTags = filterTags;
            return this.doSearchWithConsecutiveRequestManagement();
        }
    }

    showElement (elementKey) {
        let element = this[elementKey];
        element.classList.remove("hide");
    }
    hideElement (elementKey) {
        let element = this[elementKey];
        element.classList.add("hide");
    }
}

export default class GroupedSearchbar {
    constructor (elementIDs, searchServletPath, filters, maxResultsPerLoad, loadMoreText, zebraStripeOptions, language) {
        // Bind necessary DOM elements to class
        this.node = document.getElementById(elementIDs.container);
        this.searchbar = document.getElementById(elementIDs.searchbar);
        this.keywordInput = document.getElementById(elementIDs.keywordInput);
        this.clearFilters = document.getElementById(elementIDs.clearFilters);
        this.noResultsContainer = document.getElementById(elementIDs.noResultsContainer);
        this.spinnerContainer = document.getElementById(elementIDs.spinnerContainer);
        this.resultsContainer = document.getElementById(elementIDs.resultsContainer);

        // Hold filter lists' DOMs in object map for easy access when modifying state
        let filterListsDOM = this.getFilterListsDOM(this.searchbar);
        let resultRowsDOM = this.getResultRowsDOM(this.resultsContainer);

        // Constants
        this.SEARCH_PATH = searchServletPath;
        this.MAX_RESULTS_PER_LOAD = parseInt(maxResultsPerLoad) || 4;
        this.ALL_FILTER_TAGS = this.getAllFilterTags(filters);
        this.LANGUAGE = language || "en";
        this.ORDER = "alpha/desc";
        this.ZEBRA_STRIPE_OPTIONS = zebraStripeOptions;

        // Initialize dynamic behavior for input, filter dropdowns, clear button w/ the GroupedSearchbar
        this.dynamicizeInput(this.keywordInput);
        this.dynamicizeTriggers(this.searchbar);
        this.dynamicizeFilters(filterListsDOM);
        this.dynamicizeClearFilters(this.clearFilters);

        // Set initial state
        let initialState = {
            filterListsDOM: filterListsDOM,
            resultRows: this.initializeResultRows(resultRowsDOM),
            filterTags: _.clone(this.ALL_FILTER_TAGS),
            keywords: "",
            lastActiveTrigger: null
        };
        this.state = initialState;

        // Style selected for initial load
        for (let filterId in filterListsDOM) {
            this.styleSelectAll(filterId);
        }

        // Add event listener to toggle dropdowns when click outside current open dropdown on mobile view
        document.addEventListener("click", (e) => {
            if (this.state.lastActiveTrigger) {
                callIfOutsideOfContainer(this.state.lastActiveTrigger, (e) => {
                    $(this.state.lastActiveTrigger).removeClass("active");
                }, e);
            }
        });

        // Fire initial search
        this.search();
    }

    getFilterListsDOM (searchbarDOM) {
        if (!searchbarDOM) {
            return;
        }

        let filterListsDOM = {};
        let filterDropdowns = searchbarDOM.getElementsByClassName("filter-dropdown");

        for (let i=0; i < filterDropdowns.length; i++) {
            let list = filterListsDOM["filter" + (i + 1)] = [];
            let filterList = filterDropdowns[i].getElementsByClassName("filter-dropdown__list")[0];
            let filters = filterList.getElementsByTagName("li");

            for (let v=0; v < filters.length; v++) {
                list[v] = filters[v];
            }
        }
        return filterListsDOM;
    }

    getResultRowsDOM (resultsContainerDOM) {
        if (!resultsContainerDOM) {
            return;
        }

        let resultRowsDOM = [];
        let rows = resultsContainerDOM.children;
        for (let i=0; i < rows.length; i++) {
            resultRowsDOM[i] = rows[i];
        }
        return resultRowsDOM;
    }

    initializeResultRows (resultRowsDOM) {
        if (!resultRowsDOM) {
            return;
        }

        return resultRowsDOM.map((rowDOM) => {
            return new GroupedSearchbarResultRow(rowDOM.dataset.filterId, rowDOM.dataset.filterTagId, rowDOM, this.SEARCH_PATH, this.LANGUAGE, this.MAX_RESULTS_PER_LOAD, this.ORDER);
        });
    }

    getAllFilterTags (filters) {
        let allFilterTags = {};

        for (let filterId in filters) {
            allFilterTags[filterId] = filters[filterId].tags;
        }
        return allFilterTags;
    }

    resetFilterTags () {
        this.state.filterTags = _.clone(this.ALL_FILTER_TAGS);
    }

    resetKeywords () {
        this.state.keywords = "";
        this.keywordInput.value = "";
    }

    dynamicizeInput (inputDOM) {
        if (!inputDOM) {
            return;
        }

        inputDOM.onkeyup = (e) => {
            if (e.keyCode === 13) {
                this.search();
                this.toggleClearFilters();
            } else {
                this.state.keywords = e.target.value;
            }
        };
    }

    dynamicizeTriggers (searchbarDOM) {
        if (!searchbarDOM) {
            return;
        }

        let filterDropdowns = searchbarDOM.getElementsByClassName("filter-dropdown");

        for (let i=0; i < filterDropdowns.length; i++) {
            filterDropdowns[i].onclick = (e) => {
                e.preventDefault();

                let filterListContainerDOM = $(e.currentTarget).find(".filter-dropdown__list")[0];
                callIfOutsideOfContainer(filterListContainerDOM, (e) => {
                    $(e.currentTarget).toggleClass("active");
                }, e);

                if (!$(e.currentTarget).is(this.state.lastActiveTrigger)) {
                    $(this.state.lastActiveTrigger).removeClass("active");
                }

                this.state.lastActiveTrigger = filterDropdowns[i];
            }
        }
    }

    dynamicizeFilters (filterListsDOM) {
        if (!filterListsDOM) {
            return;
        }

        for (let filterId in filterListsDOM) {
            filterListsDOM[filterId].forEach((item, i) => {
                let id = item.dataset.id;

                if (id === "all-selector") {
                    item.onclick = (e) => {
                        e.preventDefault();

                        let id = e.currentTarget.dataset.id;
                        let filterId = e.currentTarget.dataset.filterId;
                        let input = $(e.currentTarget).find("input")[0];

                        if (input.checked) {
                            this.deselectAll(filterId);
                        } else {
                            this.selectAll(filterId);
                        }

                        this.search();
                        this.toggleClearFilters();
                    }
                } else {
                    item.onclick = (e) => {
                        e.preventDefault();

                        let id = e.currentTarget.dataset.id;
                        let filterId = e.currentTarget.dataset.filterId;
                        let index = this.state.filterTags[filterId].indexOf(id);
                        let input = $(e.currentTarget).find("input")[0];

                        if (input.checked) {
                            if ($($(e.currentTarget).find("label")).hasClass("disabled")) {
                                this.styleDeselectAll(filterId);

                                input.checked = true;
                                // Handle all selected case where all tags are already in the filterTags list
                                this.state.filterTags[filterId] = [id];
                            } else {
                                input.checked = false;
                                this.state.filterTags[filterId].splice(index, 1);

                                // Re-apply all filters under this list when none selected
                                if (this.state.filterTags[filterId].length === 0) {
                                    this.selectAll(filterId);
                                }
                            }
                        } else {
                            input.checked = true;
                            if (this.state.filterTags[filterId].length === this.ALL_FILTER_TAGS[filterId].length) {
                                // Handle all deselected case where all tags are already in the filterTags list
                                this.state.filterTags[filterId] = [id];
                            } else {
                                this.state.filterTags[filterId].push(id);
                            }
                        }

                        this.search();
                        this.toggleClearFilters();
                    }
                }
            });
        }
    }

    dynamicizeClearFilters (clearFiltersDOM) {
        if (!clearFiltersDOM) {
            return;
        }

        // Reset all filters to the default initial onload values
        clearFiltersDOM.onclick = (e) => {
            for (let filterId in this.state.filterListsDOM) {
                this.styleSelectAll(filterId);
            }
            this.resetKeywords();
            this.resetFilterTags();
            this.hideElement("clearFilters");
            this.search();
        }
    }

    styleSelectAll (filterId) {
        this.state.filterListsDOM[filterId].forEach((item) => {
            let input = $(item).find("input")[0];
            input.checked = true;

            if (item.dataset.id !== "all-selector") {
                let label = $(item).find(".filter-dropdown__checkbox")[0];
                $(label).addClass("disabled");
            }
        });
    }

    styleDeselectAll (filterId) {
        this.state.filterListsDOM[filterId].forEach((item) => {
            let input = $(item).find("input")[0];
            input.checked = false;

            if (item.dataset.id !== "all-selector") {
                let label = $(item).find(".filter-dropdown__checkbox")[0];
                $(label).removeClass("disabled");
            }
        });
    }

    selectAll (filterId) {
        this.styleSelectAll(filterId);
        this.state.filterTags[filterId] = _.clone(this.ALL_FILTER_TAGS[filterId]);
    }

    deselectAll (filterId) {
        this.styleDeselectAll(filterId);
        this.state.filterTags[filterId] = _.clone(this.ALL_FILTER_TAGS[filterId]);  // Still want ALL tags from this filter to apply to the search servlet params
    }

    toggleClearFilters () {
        // Show clear filters button when there are user modified filters to be cleared
       let showClear = this.state.keywords !== "";

        // Check filters if no keywords
        if (!showClear) {
            for (let filterId in this.state.filterTags) {
                if (this.state.filterTags[filterId].join(",") !== this.ALL_FILTER_TAGS[filterId].join(",")) {
                    showClear = true;
                }
            }
        }

        if (showClear) {
            this.showElement("clearFilters");
        } else {
            this.hideElement("clearFilters");
        }
    }

    search () {
        this.hideElement("resultsContainer");
        this.hideElement("noResultsContainer");
        this.showElement("spinnerContainer");

        let rowSearchPromises = [];

        this.state.resultRows.forEach((row) => {
            rowSearchPromises.push(row.getResults(this.state.keywords, this.state.filterTags));
        });

        Promise.all(rowSearchPromises).then(() => {
            // Get rows w/ results to modify some styling
            let rowsWithResults = this.state.resultRows.filter((row) => {
                return !row.node.classList.contains("hide");
            });

            // Apply zebra stripes
            if (this.ZEBRA_STRIPE_OPTIONS.isUse === "true") {
                rowsWithResults.forEach((row, i) => {
                    // Remove any instances of previous row colouring
                    row.node.classList.remove(this.ZEBRA_STRIPE_OPTIONS.rowEvenBackgroundColor);
                    row.node.classList.remove(this.ZEBRA_STRIPE_OPTIONS.rowOddBackgroundColor);

                    // Apply color for current row position
                    if (i % 2 === 0) {
                        row.node.classList.add(this.ZEBRA_STRIPE_OPTIONS.rowEvenBackgroundColor);
                    } else {
                        row.node.classList.add(this.ZEBRA_STRIPE_OPTIONS.rowOddBackgroundColor);
                    }
                });
            }

            this.hideElement("spinnerContainer");

            // Show resultsContainer if any result rows have results o/w show noResultsContainer
            if (rowsWithResults.length === 0) {
                this.showElement("noResultsContainer");
                this.hideElement("resultsContainer");
            } else {
                this.showElement("resultsContainer");
                this.hideElement("noResultsContainer");
            }

            // Shave off results cards content to restricted height
            shave(".card--resource-card__heading", 50);
            shave(".card--resource-card__body", 100);
        });
    }

    showElement (elementKey) {
        let element = this[elementKey];
        element.classList.remove("hide");
    }
    hideElement (elementKey) {
        let element = this[elementKey];
        element.classList.add("hide");
    }
}
window.GroupedSearchbar = GroupedSearchbar;     // Expose to be accessible globally