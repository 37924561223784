"use strict";
import _ from "underscore";
import Promise from "bluebird";

Promise.config({
    // Enable cancellation
    cancellation: true,
    longStackTraces: true,
    warnings: true
});


export default class DoceboCourseDetails {
    constructor (elementIDs, args) {
        // Bind necessary DOM elements to class
        this.node = document.getElementById(elementIDs.container);
        this.loadingSpinner = document.getElementById(elementIDs.loadingSpinner);
        this.background = document.getElementById(elementIDs.background);
        this.title = document.getElementById(elementIDs.title);
        this.description = document.getElementById(elementIDs.description);
        this.sessions = document.getElementById(elementIDs.sessions);
        this.button = document.getElementById(elementIDs.button);

        // Constants
        this.ARGS = args;
        this.COURSE_TYPES = {
            ON_DEMAND: "elearning",
            ILT: "classroom"
        };

        // Set initial state
        let initialState = {
            details: null
        };
        this.state = initialState;

        // Fire details request
        this.doGetCourseDetails();
    }

    getCSRFToken () {
        return new Promise((resolve, reject, onCancel) => {
            let xhr = new XMLHttpRequest();
            xhr.onload = () => resolve(xhr);
            xhr.onerror = () => reject(xhr);
            xhr.open("GET", "/libs/granite/csrf/token.json", true);
            xhr.send(null);
            onCancel(() => {
                xhr.abort();
            });
        });
    }

    getCourseDetails (query, token) {
        // Use Bluebird Promise for cancel support
        return new Promise((resolve, reject, onCancel) => {
            let xhr = new XMLHttpRequest();
            xhr.onload = () => resolve(xhr);
            xhr.onerror = () => reject(xhr);
            xhr.open("GET", '/bin/ping/doceboCourseDetails?' + query, true);
            xhr.setRequestHeader("CSRF-Token", token);
            xhr.send();
            onCancel(() => {
                xhr.abort();
            });
        });
    }

    buildQuery (id) {
        const params = {
            id: id
        };

        const esc = encodeURIComponent;
        return Object.keys(params)
            .map(k => esc(k) + "=" + esc(params[k]))
            .join("&");
    }

    doGetCourseDetails () {
        return this.getCSRFToken()
            .then(response => {
                if(response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                const msg = JSON.parse(response.responseText);
                let query = this.buildQuery(this.ARGS.courseId);
                return this.getCourseDetails(query, msg.token);
            })
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return JSON.parse(response.responseText);
            })
            .then(data => {
                this.state.details = data.details;
            })
            .catch(function (e) {
                throw new Error(e);
            })
            .finally(() => {
                this.render(
                    this.state.details.background,
                    this.state.details.product,
                    { t1Price: this.state.details.t1Price, t2Price: this.state.details.t2Price },
                    this.state.details.title,
                    this.state.details.description,
                    this.state.details.type,
                    this.state.details.sessions
                );
            });
    }

    setBackground (type, backgroundImage, product, price) {
        let image = this.background.getElementsByClassName("background__image")[0];
        image.style.backgroundImage = 'url("https:' + backgroundImage + '")';

        let content = this.background.getElementsByClassName("background__content")[0];
        let frag = document.createDocumentFragment();

        let productElement = document.createElement("h2");
        productElement.className = "headline__title heading--secondary-light align--center text--white";
        let productParagraph = document.createElement("p");
        productParagraph.style.textTransform = "none";
        productParagraph.textContent = product;
        productElement.appendChild(productParagraph);

        let priceElement = document.createElement("span");
        priceElement.className = "headline__body align--center text--white";
        if (type === this.COURSE_TYPES.ON_DEMAND) {
            priceElement.appendChild(this.createPriceTextElements(this.ARGS.tier1PricingText, price.t1Price));
            priceElement.appendChild(this.createPriceTextElements(this.ARGS.tier2PricingText, price.t2Price));
        } else {
            priceElement.appendChild(this.createPriceTextElements(this.ARGS.tier1PricingText, price.t1Price));
        }

        frag.appendChild(productElement);
        frag.appendChild(priceElement);
        content.appendChild(frag);
    }

    createPriceTextElements (text, price) {
        let priceParagraph = document.createElement("p");
        let priceSpan = document.createElement("span");
        priceSpan.className = "subheading--secondary-light";
        priceSpan.textContent = price ? text.replace(new RegExp("{price}", "g"), price) : "";
        priceParagraph.appendChild(priceSpan);
        return priceParagraph;
    }

    setTitle (title) {
        this.title.textContent = title;
    }

    setDescription (description) {
        $(this.description).append($.parseHTML($.parseHTML(description, false)[0].data));
    }

    setCTA (type, sessions) {
        if (type === this.COURSE_TYPES.ILT && this.sessions) {
            if (sessions.length > 0) {
                let frag = document.createDocumentFragment();
                sessions.forEach(session => {
                    let item = document.createElement("li");
                    item.classList.add("docebo__course-details__session");
                    item.dataset.sessionId = session.id;
                    item.onclick = this._handleAddToCart.bind(this, session);

                    let link = document.createElement("a");
                    link.textContent = session.name;

                    item.appendChild(link);
                    frag.appendChild(item);
                });
                let list = this.sessions.getElementsByTagName("ul")[0];
                list.appendChild(frag);
                this.showElement("sessions");
            }
        } else {
            // TODO: check if user already enrolled w/ SFDC & show registeredCtaText instead here & for sessions
            //  - also no need to bind onclick for add-to-cart in that case
            this.button.textContent = this.ARGS.enrollCtaText;
            this.button.onclick = this._handleAddToCart.bind(this, null)
            this.showElement("button");
        }
    }

    showElement (elementKey) {
        let element = this[elementKey];
        element.classList.remove("hide");
    }
    hideElement (elementKey) {
        let element = this[elementKey];
        element.classList.add("hide");
    }

    _handleAddToCart (session) {
        // TODO: remove
        this.state.details.t1Price = 1000.00;
        this.state.details.t2Price = 5000.00;

        let item = {
            productId: this.state.details.t1Code,
            title: this.state.details.title + (session ? " | " + session.name : ""),
            link: window.location.href,
            unitPrice: this.state.details.t1Price,
            thumbnail: window.location.protocol + this.state.details.thumbnail,
            quantity: "1",
            source: ShoppingCart.prototype.ITEM_SOURCE.DOCEBO_COURSE,
            additionalDataJson: "{" +
                "\"type\": \"" + this.state.details.type + "\"," +
                "\"sessionId\": \"" + (session ? session.id : "n/a") + "\"," +
                "\"t1Code\": \"" + this.state.details.t1Code + "\"," +
                "\"t2Code\": \"" + this.state.details.t2Code + "\"," +
                "\"t1Price\":" + (this.state.details.t1Price || 0) + "," +
                "\"t2Price\":" + (this.state.details.t2Price || 0) +
            "}"
        };
        ShoppingCart.prototype.addItem(item);
    }

    render (backgroundImage, product, price, title, description, type, sessions) {
        this.setBackground(type, backgroundImage, product, price);
        this.setTitle(title);
        this.setDescription(description);
        // TODO: commented out for phase 1; uncoment for phase 2
        if (isStage) {
            this.setCTA(type, sessions);
        }

        this.showElement("node");
        this.hideElement("loadingSpinner");
    }
}
window.DoceboCourseDetails = DoceboCourseDetails;   // Expose to be accessible globally