"use strict";

import _ from "underscore";
import ModalToggle from "../utils/ModalToggle.js";

export default class Modal {
    constructor(opts) {
        this._mountModal = this._mountModal.bind(this);
        this._unmountModal = this._unmountModal.bind(this);
        this._positionModal = this._positionModal.bind(this);
        this._createPageModal = this._createPageModal.bind(this);
        this._createVideoModal = this._createVideoModal.bind(this);
        this._createModal = this._createModal.bind(this);
        this._resizeIframe = this._resizeIframe.bind(this);

        this.options = _.defaults(opts, {
            type: "page",
            source: null,
            contextClassname: "iframe-modal"
        });

        if (this.options.source) {
            switch (this.options.type) {
            case "page":
                this._createPageModal();
                break;
            case "video":
                this.options.contextClassname = "video-modal";
                this._createVideoModal();
                break;
            case "element":
                this.options.contextClassname = "modal--element";
                this._createElementModal();
                break;
            default:
                break;
            }
        }
    }
    _mountModal(content) {
        this._createModal(content);
        document.body.appendChild(this.modal);
        ModalToggle.open();
        $(this.modalBody).html(content);
        this.modalBody.appendChild(this.modalClose);
        this.modal.style.opacity = 1;
        this.options.type == "video" && this._positionVideoModal();
        if (this.options.type == "element") {
            this.modalBody.style.opacity = 1;
        } 
    }
    _unmountModal() {
        document.body.removeChild(this.modal);
        ModalToggle.close();
    }
    _positionModal() {
        let width = $(this.modalBody).width() + "px";
        let height = $(this.modalBody).height() + "px";
        this.modalBody.style.left = `calc(50% - ${width} / 2)`;
        this.modalBody.style.top = `calc(50% - ${height} / 2)`;
        this.modalBody.style.opacity = 1;
    }
    _positionVideoModal() {
        let width = $(window).width() * 0.9;
        let height = width * 0.5625;

        if (height > $(window).height()) {
            height = $(window).height() * 0.9;
            width  = height * 1.5625;
        }

        this.modalBody.style.width = width + "px";
        this.modalBody.style.height = height + "px";
        this.modalBody.style.opacity = 1;
    }
    _createPageModal() {
        let iframe = document.createElement("iframe");
        iframe.src = this.options.source;
        iframe.onload = this._resizeIframe;
        this._mountModal(iframe);
    }
    _createElementModal() {
        let source = document.getElementById(this.options.source);
        let content = source.cloneNode(true);
        content.style.display = "block";
        content.id = content.id + "Clone";
        this._mountModal(content);
    }
    _resizeIframe(e) {
        let iframe = e.target;

        iframe.width = iframe.contentWindow.document.body.scrollWidth;
        iframe.height = iframe.contentWindow.document.body.scrollHeight;

        this._positionModal();
    }
    _createVideoModal() {
        let container = document.createElement("div");
        container.className = "video__container";
        let video = document.createElement("video");
        video.className = "video-js";
        video.id = `video${this.options.source}`;
        video.setAttribute("data-video-id", this.options.source);
        video.setAttribute("data-account", "771836189001");
        video.setAttribute("data-player", "default");
        video.setAttribute("data-embed", "default");
        video.setAttribute("autoplay", true);
        //video.onloadstart = this._positionModal;
        
        console.log('Modal autoplaying video : ' + this.options.source);

        let script1 = document.createElement("script");
        script1.src = "//players.brightcove.net/771836189001/default_default/index.min.js";
        script1.async = true;

        let script2 = document.createElement("script");
        script2.src = "https://players.brightcove.net/videojs-custom-endscreen/dist/videojs-custom-endscreen.min.js";
        script2.async = true;

        container.appendChild(video);
        container.appendChild(script1);
        container.appendChild(script2);

        this._mountModal(container);
    }

    _createModal(content) {
        this.modal = document.createElement("div");
        this.modal.className = "modal";
        $(this.modal).addClass(this.options.contextClassname);
        this.options.className &&
        $(this.modal).addClass(this.options.className);
        this.modal.style.opacity = 0;

        this.modalClose = document.createElement("div");
        this.modalClose.className = "modal-close";
        this.modalClose.onclick = this._unmountModal;

        this.modalOverlay = document.createElement("div");
        this.modalOverlay.className = "modal-overlay";
        this.modalOverlay.onclick = this._unmountModal;

        this.modalBody = document.createElement("div");
        this.modalBody.className = "modal-body";
        this.modalBody.style.opacity = 0;

        this.modal.appendChild(this.modalOverlay);
        this.modal.appendChild(this.modalBody);
    }
}
