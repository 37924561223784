export default {
    open: () => {
        let top = $(window).scrollTop();
        let width = $(document.body).width();
        $(document.body).addClass("modal--open");
        $(document.body).css("margin-top", -top);
        //$(document.body).width(width);
    },
    close: () => {
        $(document.body).removeClass("modal--open");
        let bodyTop = $(document.body).css("margin-top");
        $(document.body).css("margin-top", 0);
        let windowTop = -1 * parseInt(bodyTop);
        $(window).scrollTop(windowTop);
    }
}
