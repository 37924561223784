"use strict";
import _ from "underscore";
import fetch from "isomorphic-fetch";
import shave from 'shave';
//import headings from "../../styles/design-tokens/classnames/headings.json";
import {props as bgColors} from "../../../../../../../../../../../../../node_modules/@pingux/marketing/styles/design-tokens/classnames/background-colors.json";
import {props as textColors} from "../../../../../../../../../../../../../node_modules/@pingux/marketing/styles/design-tokens/classnames/text-colors.json";
import {props as buttonStyles} from "../../../../../../../../../../../../../node_modules/@pingux/marketing/styles/design-tokens/classnames/buttons.json";
import Promise from "bluebird";
import pluralize from "pluralize";

Promise.config({
    // Enable cancellation
    cancellation: true,
    longStackTraces: true,
    warnings: true
});

function stripQuotes(string) {
    return string.replace(/'/g,"");
}

const styles = _.mapObject(Object.assign({}, bgColors, textColors, buttonStyles), (value, key) => {
    return stripQuotes(value.value);
});

function Searchbar (props) {
    const searchbar = document.createElement("div");
    searchbar.className = "search-banner__searchbar";

    const icon = document.createElement("i");
    icon.className = "fa fa-search";
    // TODO: make this workd
    icon.onclick = e => props.onClick;

    const container = document.createElement("div");
    container.className = "searchbar__input-container";

    const input = document.createElement("input");
    input.type = "text";

    input.placeholder = props.placeHolderText;

    input.onkeyup = e => props.onKeyUp(e.target.value, e.keyCode, e);
    icon.onclick = e => props.onClick(input.value);

    container.appendChild(input);
    searchbar.appendChild(icon);
    searchbar.appendChild(container);

    return searchbar;
}

function ListItem (props) {
    const item = document.createElement("div");
    item.className = "filter-dropdown__item";


    if (props.hasCheckbox) {
        item.appendChild(CheckboxLabel(props))
    }
    else {
        item.innerHTML = `<span class="filter-dropdown__item-title">${props.title}</span>`;
    }
    return item;
}
function CheckboxLabel (props) {
    const label = document.createElement("label");
    const className = props.disabled ? "disabled" : "";
    label.className = `filter-dropdown__checkbox ${className}`;

    const checkbox = document.createElement("input");
    checkbox.type = "checkbox";
    checkbox.checked = props.checked;

    checkbox.onclick = e => {
    	var stateObj = checkbox.id;
    	if(!window.location.href.includes("filtered=true")) {
    		var queryJoiner = window.location.href.indexOf('?')!=0 ? '&' : '?';
    		window.history.pushState(stateObj, "Filtered", window.location.href + queryJoiner + "filtered=true");
    	}
    	props.onItemClick(props.id, props.parentID, e);
    };

    const icon = document.createElement("div");
    icon.className = "icon";

    const title = document.createElement("span");
    title.className = "filter-dropdown__item-title";
    title.innerHTML = props.title;

    label.appendChild(checkbox);
    label.appendChild(icon);
    label.appendChild(title);

    return label;
}
function SelectDropdown (props) {
    const dropdown = document.createElement("div");

    if(props.hidden === "true") {
    		dropdown.className = "filter-dropdown hidden";
    }else{
    		dropdown.className = "filter-dropdown";
    }

    const dropdownTrigger = document.createElement("a");
    dropdownTrigger.className = "filter-dropdown__trigger";


    dropdownTrigger.innerHTML = props.title;
    dropdownTrigger.onclick = (e) => {

    		$(dropdown).toggleClass("active");
    		props.onClick && props.onClick(props.id, dropdown, e);
    }

    const dropdownList = document.createElement("div");
    dropdownList.className = "filter-dropdown__list";

    _.each(props.items, (item) => {
        dropdownList.appendChild(item.node);
    });

    dropdown.appendChild(dropdownTrigger);
    dropdown.appendChild(dropdownList);
    return dropdown;
}

function isInView (node, offset) {
    const top = $(node).offset().top;
    const bottom = top + $(node).height();
    const windowTop = $(window).scrollTop();
    const windowBottom = windowTop + $(window).height();
    if (top - offset <= windowBottom && bottom + offset >= windowTop) {
        return true;
    }
    return false;
}

function PillButton (props) {
    const button = document.createElement("a");
    if(props.path.includes('/company/partners/partner-directory')) {
    		button.className = `button ${styles["BUTTON-SMALL"]} ${styles["BUTTON-HOLLOW-LIGHT"]} button--pill-button partner-filter-dropdown__clear-button`;
    } else {
    		button.className = `button ${styles["BUTTON-SMALL"]} ${styles["BUTTON-HOLLOW-LIGHT"]} button--pill-button filter-dropdown__clear-button`;
    }

    button.onclick = () => {props.onClick()};
    button.innerHTML = props.text;

    return button;
}

const TYPES = {
    ADD_ALL_TAGS: "ADD_ALL_TAGS",
    TAG_SEARCH: "TAG_SEARCH",
    STRING_SEARCH: "STRING_SEARCH",
    LOAD_MORE: "LOAD_MORE",
    INITIAL_LOAD: "INITIAL_LOAD",
    INITIAL_TAG: "INITIAL_TAG"
}

class PingSearchbar {
    constructor (id, opts) {

    	this._initalPreset = this._initalPreset.bind(this);
        this._selectItem = this._selectItem.bind(this);
        this._search = this._search.bind(this);
        this._getToken = this._getToken.bind(this);
        this._searchAction = this._searchAction.bind(this);
        this._buildQuery = this._buildQuery.bind(this);
        this._onInputChange = this._onInputChange.bind(this);
        this._onIconClick = this._onIconClick.bind(this);
        this._filterItem = this._filterItem.bind(this);
        this._getResultsType = this._getResultsType.bind(this);
        this._clearFilters = this._clearFilters.bind(this);

        this._update = this._update.bind(this);
        this._toggleAll = this._toggleAll.bind(this);
        this._toggleChildren = this._toggleChildren.bind(this);
        this._createTagItemObject = this._createTagItemObject.bind(this);

        this._dividedSearch = this._dividedSearch.bind(this);
        this._loadMoreSearch = this._loadMoreSearch.bind(this);

        this.node = document.getElementById(id);
        this.node.className = "search-banner";

        this.container = document.createElement("div");
        this.container.className = "container";

        this.inputValue = "";
        this.clearFilterText = "CLEAR FILTERS";

        // Set to avoid null check later
        this.tokenPromise = Promise.resolve();
        this.searchPromises = [Promise.resolve()];

        this.resultsComponent = opts.resultsComponent;
        this.MAX_RESULTS = 20;

	    window.addEventListener('popstate', e => {
			this._clearFilters();
		});

        this.sortOptions = {
            property: {
                alpha: "alpha",
                date: "date"
            },
            order: {
                asc: "asc",
                desc: "desc"
            }
        };
        this.options = _.defaults(opts, {
            paths: null,
            tagGroups: null,
            allTitle: "All",
            allColor: styles["BG-BERMUDA-GRAY"],
        });

        let queryFilters = {
        	topic: opts.queryFilters.topicQuery ? opts.queryFilters.topicQuery.split(",") : [],
            type : opts.queryFilters.typeQuery ? opts.queryFilters.typeQuery.split(",") : [],
        };

        if (this.options.noResultsId) {
            let term = "${searchterm}"
            let node = $(`#${this.options.noResultsId} p:contains("${term}")`)[0];
            if (node) {
                let newText = $(node).text().replace(term, "<span class='pingsearch-placeholder'></span>");
                node.innerHTML = newText;
                this.noResultsPlaceholder = $("span.pingsearch-placeholder")[0];
            }
        }

        if(this.options.paths.join().includes('/company/partners/partner-directory')) {
			this.options.allColor = styles["BG-WHITE"];
        }
        if(this.options.paths.join().includes('/resources/product-releases')) {
			this.options.allColor = styles["BG-WHITE"];
        }

        // these are shortcuts to pass the style props to the rows, second is
		// the all results
        this.styleThemes = [
            {
                bgColor: styles["BG-WHITE"],
                titleColor: styles["TEXT-BERMUDA-GRAY"],
                loadMoreStyle: styles["BUTTON-SECONDARY"],
                filterStyle: styles["BUTTON-HOLLOW-BLUE"]
            },
            {
                bgColor: this.options.allColor,
                titleColor: styles["TEXT-WHITE"],
                loadMoreStyle: styles["BUTTON-WHITE"],
                filterStyle: styles["BUTTON-HOLLOW-LIGHT"]
            },
        ];

        if(this.options.paths.join().includes('/company/partners/partner-directory')) {

        		this.styleThemes[0].bgColor = styles["BG-PATTENS-BLUE"];
    			this.styleThemes[1].titleColor = styles["TEXT-GRAY"];
    			this.styleThemes[0].titleColor = styles["TEXT-GRAY"];
    			this.styleThemes[1].loadMoreStyle = styles["BUTTON-SECONDARY"];
        }

        this.clearFilters = PillButton({text: this.options.clearFilterText, onClick: this._clearFilters, path: this.options.paths.join()});
        // Create Nodes and Data Objects for the tag groups

    	if(this.options.paths.join().includes('/company/partners/partner-portal')) {
    		this.node.className = "partner__search-banner";
    		this.options.divideResultsBy = "Topic";
    	}

    	if(this.options.paths.join().includes('/company/partners/partner-directory')) {
    		this.node.className = "partner__search-banner";
    	}

        this.tagGroups = _.map(this.options.tagGroups, (group, i) => {
	        	// TODO: use something other than title
	        	if (group.tagGroupLabel == this.options.divideResultsBy) {
                this.dividedTagGroupIndex = i;
            }
            const lowercase = group.tagGroupLabel.toLowerCase();
            let isTypeGroup;
            if (lowercase === this.options.typeGroupName) {
                isTypeGroup = true;
                this.resultsTypes = {};
            }

            // TODO: something other than string for this
            const selectAllTag = [{
                tagID: "",
                title: `${group.allTagGroupLabel}`
            }];
            const tags = selectAllTag.concat(group.tags);
            const items = _.map(tags, (tag, j) => {
                let callback;
                // if selectAllTag
                if (j === 0) {
                    callback = this._toggleAll;
                }
                else {
                    callback = this._selectItem;
                }
                if (isTypeGroup) {
                    this.resultsTypes[tag.tagID] = tag.title;
                }
                return this._createTagItemObject(tag, j, i, callback);
            });


            return {
                id: i,
                label: group.tagGroupLabel,
                allChecked: true,
                tags: tags,
                // all tags without select all
                allTags: _.pluck(group.tags, "tagID"),
                children: items,
                node: SelectDropdown({id: i, hidden: group.hidden, title: group.tagGroupLabel, items: items, onClick: _.noop(), path: this.options.paths.join()})

            }
        });
        // Add Searchbar And Dropdowns to the DOM
        this.container.appendChild(Searchbar({onClick: this._onIconClick, onKeyUp: this._onInputChange, placeHolderText: this.options.placeHolderText}));

        _.each(this.tagGroups, (group) => {
            this.container.appendChild(group.node);
        });
        this.node.appendChild(this.container);

        this.DEFAULT_SEARCH_PARAMS = {
            // sent as keywords to search
            searchString: this.inputValue,
            // changes to tags1 in query
            tags0: [],
            // changes to tags2 in query
            tags1: [],
            path: this.options.paths.join(),
            language: this.options.language,
            limit: this.MAX_RESULTS,
            offset: 0,
            order: `${this.sortOptions.property.date}/${this.sortOptions.order.desc}`
        }
        this.DEFAULT_RESULTS_PROPS = {
            title: "All",
            assetKey : "All",
            hasMore: false,
            results: [],
            styles: {},
            callback: _.noop,
            pluralizeActive : this.options.language==='en'
        }
        this.DEFAULT_RESULTS_OBJECT = {
            instance: null,
            params: {}
        }
        this.initialState = {
            searchType: null,
            searchString: "",
            results: {},
            tags0: _.isEmpty(opts.queryFilters) ? this.tagGroups[0].allTags : queryFilters.topic,
            tags1: _.isEmpty(opts.queryFilters) ? this.tagGroups[1].allTags : queryFilters.type
        }
        this._setState(this.initialState);

        if (!_.isEmpty(opts.queryFilters)) {
        	let nextState = _.clone(this.state);

        	if (queryFilters.topic.length!=0) {
        		this._initalPreset(0, queryFilters.topic);
        	}

        	if (queryFilters.type.length!=0) {
        		this._initalPreset(1, queryFilters.type);
        	}

        	this._setState(nextState);
        }

    	 this._searchAction({type: _.isEmpty(opts.queryFilters) ? TYPES.INITIAL_LOAD : TYPES.INITIAL_TAG });

    }

    _initalPreset(parentID, tags) {
    	const parent = this.tagGroups[parentID];
    	parent.allChecked = false;

        this._toggleChildren(parent, false, null);

    	for(var i = 0; i < tags.length; i++) {
    		for (var j = 0; j < parent.allTags.length; j++) {
    			if (tags[i]==parent.allTags[j]) {
    				_.each(parent.children, (child, k) => {
    					if (k==(j+1)) {
    						child.node.getElementsByTagName("input")[0].checked = true;
    					}
    				});
    			}
    		}
		}

    	this._getCheckedTags(parent)
    }

    // finds the results type from tag array
    _getResultsType(tags) {
        const t = _.find(tags, tag => this.resultsTypes.hasOwnProperty(tag));
        return this.resultsTypes[t];
    }

    _createTagItemObject(tag, id, parentID, onClick) {
        return {
            id: id,
            parentID: parentID,
            tagID: tag.tagID,
            title: tag.title,
            assetKey : tag.assetKey,
            checked: true,
            node: ListItem({
                id: id,
                parentID: parentID,
                title: tag.title,
                hasCheckbox: true,
                disabled: (id > 0), // add disabled style to all tags except
									// select all option
                checked: true,
                onItemClick: onClick
            })
        }
    }

    _toggleChildren(parent, checked, exception) {
        _.each(parent.children, (child, i) => {
            const childNode = child.node;
            const label = childNode.getElementsByTagName("label")[0];
            if (i !== 0) {
                if (!checked) {
                    $(label).removeClass('disabled');
                }
                else {
                    if (i !== exception) {
                        $(label).addClass('disabled');
                    }
                }
            }
            if (i === exception) {
                return;
            }
            childNode.getElementsByTagName("input")[0].checked = checked;
        })
    }
    _clearFilters() {
    		let inputs = document.getElementsByTagName("input");
    		for(var i=0; i < inputs.length; i++) {
    			if(inputs[i].getAttribute('type')=='text'){
    				inputs[i].value = "";
			}
    		}
        _.each(this.tagGroups, (group, i) => {
            this._toggleAll(null, i, null, true);
        });

    }

    _toggleAll(id, parentID, e, forceCheck = false) {
        const parent = this.tagGroups[parentID];
        const checked = forceCheck || e.target.checked;
        const skipIndex = forceCheck ? null : 0;
        this._toggleChildren(parent, checked, skipIndex);
        this.inputValue = "";
        this._searchAction({ type: TYPES.ADD_ALL_TAGS, data: {parent: parent, allChecked: checked} });
    }


    // this triggers the onclick of an item's dom node by title
    // only works for divided Index
    _filterItem(title) {
        const child = _.find(this.tagGroups[this.dividedTagGroupIndex].children, c => c.title === title);
        child.node.getElementsByTagName("label")[0].click();
    }

    _selectItem(id, parentID, e) {
        const parent = this.tagGroups[parentID];
        // send tag to be added, parent.allChecked included because
		// e.preventDefault only fires after all the code runs
        const checked = e.target.checked || parent.allChecked;
        if (parent.allChecked) {
            e.preventDefault();
            e.stopPropagation();
            parent.allChecked = false;
            // toggle all children except current item
            this._toggleChildren(parent, false, id);
        }
        // send tag if it should be added, null if not
        const tag = checked ? parent.tags[id].tagID : null;

        this._searchAction({ type: TYPES.TAG_SEARCH, data: {parent: parent, tag: tag} });
    }

    _onInputChange(value, keyCode, e) {
        this.inputValue = value;
        if (keyCode === 13) {
            this._searchAction({type: TYPES.STRING_SEARCH})
        }
    }
    _onIconClick(value) {
        this._searchAction({type: TYPES.STRING_SEARCH})
    }
    _search(query, token) {
        // using Bluebird Promise because of the cancel support
        return new Promise((resolve, reject, onCancel) => {
            let xhr = new XMLHttpRequest();
            xhr.onload = () => resolve(xhr);
            xhr.onerror = () => reject(xhr);


            if(this.options.paths.join().includes('/resources/product-releases')) {
        			xhr.open("GET", `/bin/ping/productReleaseSearch?` + query, true);
            } else {
            		xhr.open("GET", `/bin/ping/resourceLibrarySearch?` + query, true);
            }

            xhr.setRequestHeader("CSRF-Token", token);
            xhr.send();
            onCancel(() => {
                xhr.abort();
            })
        });
    }
    _getToken() {
        return new Promise((resolve, reject, onCancel) => {
            let xhr = new XMLHttpRequest();
            xhr.onload = () => resolve(xhr);
            xhr.onerror = () => reject(xhr);
            xhr.open("GET", "/libs/granite/csrf/token.json", true);
            xhr.send(null);
            onCancel(() => {
                xhr.abort();
            })
        });
    }

    _getCheckedTags(tagGroup) {
        let checked = _.filter(tagGroup.children, child => {
            let input = child.node.getElementsByTagName("input")[0];
            return input.checked;
        });
        return _.map(checked, (item) => item.tagID);
    }

    _searchAction(action) {
        // abort the previous searches
        _.each(this.searchPromises, promise => {
            promise.cancel()}
        );

        if (this.options.noResultsId) {
            $(this.resultsComponent.node).show();
            $(`#${this.options.noResultsId}`).hide();
        }

        if (action.type !== TYPES.INITIAL_LOAD && this.options.openParsysId) {
            const name = `#${this.options.openParsysId}`;
            $(name).hide();
        }

        let nextState = _.clone(this.state);
        let searchString = this.inputValue;

        nextState.searchType = action.type;
        nextState.searchString = searchString;

        let params = action.type === TYPES.LOAD_MORE ?
            _.clone(this.state.results[action.data].params) :
            _.defaults({
                searchString: searchString,
                tags0: nextState.tags0,
                tags1: nextState.tags1,
                order: searchString ? null : undefined
            }, this.DEFAULT_SEARCH_PARAMS);

        switch (action.type) {
            // takes string as data
            case TYPES.STRING_SEARCH:
                this._dividedSearch(nextState, params);
                break;
            // takes parent tagGroup and tag as data
            case TYPES.TAG_SEARCH:
                nextState[`tags${action.data.parent.id}`] = this._getCheckedTags(action.data.parent);
                // need to add tag because of prevent default on all tag
				// selection
                if (action.data.tag && !nextState[`tags${action.data.parent.id}`].includes(action.data.tag)) {
                    nextState[`tags${action.data.parent.id}`].push(action.data.tag);
                }
                params[`tags${action.data.parent.id}`] = nextState[`tags${action.data.parent.id}`];

                // if there are tags selected, do a search
                if (params[`tags${action.data.parent.id}`].length !== 0) {
                    this._dividedSearch(nextState, params);

                }
                // if there are no tags, toggle all
                else {
                    this._toggleAll(null, action.data.parent.id, null, true);
                }
                break;
            // takes parent tagGroup as data.parent
            case TYPES.ADD_ALL_TAGS:
                action.data.parent.allChecked = action.data.allChecked;
                nextState[`tags${action.data.parent.id}`] = action.data.parent.allTags.slice();
                params[`tags${action.data.parent.id}`] = nextState[`tags${action.data.parent.id}`]
                action.data.parent.allTagsSearch = true;
                if(params.path.includes('/resources/client-library')) {
                		params.limit = 4;
                		this._dividedSearch(nextState, params, true, true);
                } else {
                		this._dividedSearch(nextState, params, false, false);
                }

                break;
            case TYPES.LOAD_MORE:
                const instance = this.state.results[action.data].instance;
                params.offset = params.offset + params.limit;
                this._loadMoreSearch(nextState, params, instance, action.data);
                break;
            case TYPES.INITIAL_TAG:
            	this._dividedSearch(nextState, params);
            	break;
            case TYPES.INITIAL_LOAD:
                params.offset = 0;
                let forceDivide = true;
                let filter = true;
                if (params.path.includes('/company/partners/partner-portal')) {
            		params.limit = 12;
	            }else if (params.path.includes('/company/partners/partner-directory')){
	            		forceDivide = false;
	            		filter = false;
	            		params.limit = 18;
	            }else{
	            		params.limit = 4;
	            }
                this._dividedSearch(nextState, params, forceDivide, filter);
                break;
        }
    }
    _loadMoreSearch(nextState, params, instance, title) {
        // UPDATE the dom on the instance
        instance.hideLoadButton();
        instance.showSpinner();
        this.searchPromises = [this._doSearch(params)
            .then((data) => {
                const resultsProps = _.defaults({
                    title: title,
                    loadMoreText: this.options.loadMoreText,
                    viewAllText: this.options.viewAllText,
                    hasMore: data.hasMore,
                    results: data.results
                }, this.DEFAULT_RESULTS_PROPS);
                const resultsObject = {
                    instance: this._update(resultsProps, instance, nextState),
                    params: data.params
                }
                nextState.results[title] = resultsObject;
                // calling for second time to update results
                this._setState(nextState);
                shave(".card--resource-card__heading", 50);
                shave(".card--resource-card__body", 100);
            })
            .finally(() => {

            })
        ];
    }
    _dividedSearch(nextState, params, forceDivide = false, filter = false) {
        // calling set state here in case another search is called
        // cancel promise is fired too late for state to be up to date
        this._setState(nextState);
        this.resultsComponent.clear();
        this.resultsComponent.showSpinner();
        nextState.results = {};

        const dividedTags = this.dividedTagGroupIndex !== undefined ? nextState[`tags${this.dividedTagGroupIndex}`] : null;

        // if not all tags, divide the search
        if ((dividedTags.length > 0) && (dividedTags.length !== this.tagGroups[this.dividedTagGroupIndex].allTags.length || forceDivide)) {
            this.searchPromises = _.map(dividedTags, (tag, i) => {
                const thisParams = _.defaults({
                    [`tags${this.dividedTagGroupIndex}`]: [tag]
                }, params);
                const findTag = _.find(this.tagGroups[this.dividedTagGroupIndex].tags, t => t.tagID === tag);
                return this._doSearch(thisParams, (findTag ? findTag.title : ""), (findTag ? findTag.assetKey : ""));
            });
        }

        // otherwise just do a large search
        else {
            const thisParams = _.defaults({
                tags0: nextState.tags0.slice(),
                tags1: nextState.tags1.slice()
            }, params);

            if (nextState.searchType === TYPES.INITIAL_LOAD || nextState.searchType === TYPES.ADD_ALL_TAGS) {
            		this.searchPromises = [this._doSearch(thisParams, "")];
            } else {
            		this.searchPromises = [this._doSearch(thisParams, "")];
            }

        }

        Promise.all(this.searchPromises)
        .then((datas) => {
            // filter empty search results
            const filtered = _.filter(datas, (data) => {
                return data.results.length > 0;
            });

            _.each(filtered, (data, i) => {
                // alternate the styles based on search type
            		let control = i;

            		let theme = (nextState.searchType === TYPES.INITIAL_LOAD || nextState.searchType === TYPES.ADD_ALL_TAGS) ? i : i + 1;

                let styles = Object.assign({}, this.styleThemes[theme % 2]);
                // add background image style for initial load

                if ((nextState.searchType === TYPES.INITIAL_LOAD || nextState.searchType === TYPES.ADD_ALL_TAGS) &&  theme % 2 && data.title) {
                    // lowercase the title, replace spaces with -, remove
					// trailing s if plural
                    styles.bgColor = `bg--${pluralize.singular(data.assetKey.toLowerCase().replace(/ /g, "-"))} overlay--dark bg--default-image `;

                }

                if(this.options.paths.join().includes('/company/partners/partner-directory') && (nextState.searchType === TYPES.INITIAL_LOAD || nextState.searchType === TYPES.ADD_ALL_TAGS)) {
                		styles.bgColor = `bg--white`;
                }
                if(this.options.paths.join().includes('/resources/product-releases') && (nextState.searchType === TYPES.INITIAL_LOAD || nextState.searchType === TYPES.ADD_ALL_TAGS)) {
            		styles.bgColor = `bg--white`;
            		styles.filterStyle = `button--hollow-blue`;
                }

                const resultsProps = _.defaults({
                		path: data.params.path,
                    title: data.title,
                    loadMoreText: this.options.loadMoreText,
                    viewAllText: this.options.viewAllText,
                    assetKey : data.assetKey,
                    getType: this._getResultsType,
                    hasMore: data.hasMore,
                    results: data.results,
                    styles: styles,
                    filter: filter && data.hasMore ? this._filterItem.bind(null, data.title) : null,
                    loadMore: data.hasMore ? this._searchAction.bind(null, {type: TYPES.LOAD_MORE, data: data.title}) : null
                }, this.DEFAULT_RESULTS_PROPS);
                const resultsObject = {
                    instance: this._update(resultsProps, null, nextState),
                    params: data.params
                }
                nextState.results[data.title] = resultsObject;
            });


            if (filtered.length === 0 && this.options.noResultsId) {
                $(this.resultsComponent.node).hide();
                if (this.noResultsPlaceholder) {
                    this.noResultsPlaceholder.innerHTML = nextState.searchString || "your search";
                }

                $(`#${this.options.noResultsId}`).show();
            }
            // calling for second time to update results
            this._setState(nextState);

            shave(".card--resource-card__heading", 50);
            shave(".card--resource-card__body", 100);
            this.resultsComponent.hideSpinner();
        })
        .finally(() => {
        })
    }

    _doSearch(params, title, assetKey) {
        return this._getToken()
            .then(response => {
                if (response.status >= 400) {
                   throw new Error("Bad response from server");
                }
                const msg = JSON.parse(response.responseText);
                return this._search(this._buildQuery(params), msg.token);
            })
            .then(response => {
                // TODO: Add better error handling
                if (response.status >= 400) {
                   throw new Error("Bad response from server");
                }
                return JSON.parse(response.responseText);
            })
            .then(data => {
                let hasMore, results, type;
                if (data.results.length > params.limit) {
                    hasMore = true;
                    // chop off the last result to make it equal max results
                    results = data.results.slice(0, -1);
                }
                else {
                    hasMore = false;
                    results = data.results.slice();
                }
                const resultsReturn = {
                    params: params,
                    results: results,
                    hasMore: hasMore,
                    title: title,
                    assetKey: assetKey
                }
                return resultsReturn;
            })
            .catch(function(e) {
                throw new Error (e)
            })
            .finally(function() {
            });
    }

    _update(resultsProps, instance, nextState) {
        if (instance) {
            instance.hideSpinner();
            instance.renderResults(resultsProps.results, resultsProps.hasMore);
        }
        if (nextState.tags0.length !== this.tagGroups[0].allTags.length || nextState.tags1.length !== this.tagGroups[1].allTags.length || nextState.searchString !== "") {

            if (!this.container.contains(this.clearFilters)) {
                this.container.appendChild(this.clearFilters);
            }
        }
        else {
            if (this.container.contains(this.clearFilters)) {
                this.container.removeChild(this.clearFilters);
            }
        }

        return instance || this.resultsComponent.buildResultsRow(resultsProps);
    }

    _setState(nextState) {
        this.state = nextState;
    }
    _buildQuery(p) {
        const params = {
            keywords: p.searchString,
            tags1: p.tags0.join(),
            tags2: p.tags1.join(),
            path: p.path,
            language : p.language,
            // request one more than the current limit
            limit: p.limit + 1,
            offset: p.offset,
            order: p.order
        };
        const esc = encodeURIComponent;
        return Object.keys(params)
            .map(k => esc(k) + '=' + esc(params[k]))
            .join('&');
    }
}
window.PingSearchbar = PingSearchbar;
