import jump from "jump.js";

window.addEventListener('DOMContentLoaded', function(){

    var inputs = document.getElementsByClassName('picForms-text-input');
    var select = document.getElementsByClassName('picForms-select-input');
    var passwords = document.getElementsByClassName('picForms-post-input');

    for(var i=0; i < inputs.length; i++){
        inputs[i].oninput = inputFocus;
        inputs[i].onblur = inputBlur;
    }

    for(var i=0; i < select.length; i++){
        select[i].onfocus = inputFocus;
        select[i].onblur = inputBlur;
    }

    for(var i=0; i < passwords.length; i++){
        console.log("BOOM");
        passwords[i].oninput = passwordInput;
        passwords[i].onblur = passwordBlur;
    }

    function inputFocus(e) {
        var container = document.getElementById(e.target.id).parentElement;
        /* var input = document.getElementById(e.target.id);
        var label = document.getElementById(e.target.id).previousElementSibling; */

        if(container && !container.classList.contains("touched")) {
            if (container.classList.contains("ignored")) {
                container.classList.remove("ignored");
            }

            container.classList.add("touched");
        }
    }

    function inputBlur(e) {
        var container = document.getElementById(e.target.id).parentElement;
        var input = document.getElementById(e.target.id);
        var icon = document.getElementById(input.id + '-validation-icon');
        var validated = true;
        /*var label = document.getElementById(e.target.id).previousElementSibling; */

        if(container && container.classList.contains("touched") && input.value === "" || input.value === "Select country") {
            container.classList.remove("touched");
            container.classList.add("ignored");
            if (container.classList.contains("valid")) {
                container.classList.remove("valid");
                container.classList.add("invalid");
                icon.className = "fa fa-exclamation-triangle icon";
            }
        } else {
            validated = validate(input);
            if(validated) {
                if (container.classList.contains("picForms-input-container-select")) {
                    container.className = "picForms-input-container-select valid";
                } else {
                    container.className = "picForms-input-container valid";
                }
                icon.className = "fa fa-check icon";
            } else {
                if (container.classList.contains("picForms-input-container-select")) {
                    container.className = "picForms-input-container-select invalid";
                } else {
                    if (input.value === "") {
                        container.className = "picForms-input-container invalid ignored";
                    } else {
                        container.className = "picForms-input-container invalid";
                    }
                }

                icon.className = "fa fa-exclamation-triangle icon";
            }
        }
    }


    function passwordInput(e) {
        var container = document.getElementById(e.target.id).parentElement;
        var input = document.getElementById(e.target.id);

        if (isTouched(e.target.className)) {
            setInitialState(container, input);
        } else {
            setTouchedState(container, input);
        }
    }

    function passwordBlur(e) {
        var container = document.getElementById(e.target.id).parentElement;
        var input = document.getElementById(e.target.id);
        var icon = document.getElementById(input.id + '-validation-icon');
        var validated = true;

        if (input.value === "") {
            setInvalidState(container, input);
        } else {
            if (validate(input)) {
                setValidState(container, input);
            } else {
                setInvalidState(container, input);
            }
        }
    }

    function setInitialState(container, input) {
        var icon = input.previousElementSibling;

        icon.className = "invisible-icon";

        container.className = "picForms-input-container";
    }

    function setTouchedState(container, input) {
        var icon = input.previousElementSibling;

        icon.className = "invisible-icon";

        container.className = "picForms-input-container touched";
    }

    function setInvalidState(container, input) {
        var icon = input.previousElementSibling;

        icon.className = "invisible-icon";

        container.className = "picForms-input-container invalid";
    }

    function setValidState(container, input) {
        var icon = input.previousElementSibling;

        icon.className = "fa fa-check icon";

        container.className = "picForms-input-container valid";
    }

    function isTouched(className) {

        if (className === "picForms-input-container touched") {
            return true;
        } else if (className === "picForms-input-container invalid") {
            return true;
        } else if (className === "picForms-input-container valid") {
            return true;
        }
        return false;
    }

    function validate(input) {

        var emailRegex = new RegExp('.+@.+\\.[a-z]+');
        var alphaNumericRegex = new RegExp('[\p{Alphabetic}\p{alphabetic}\p{Mark}\p{Decimal_Number}\p{Connector_Punctuation}\p{Join_Control}]');
        var phoneRegex = new RegExp('^(?:(?:\\(?(?:00|\\+)([1-4]\\d\\d|[1-9]\\d?)\\)?)?[\\-\\.\\ \\\\/]?)?((?:\\(?\\d{1,}\\)?[\\-\\.\\ \\\\/]?){0,})(?:[\\-\\.\\ \\\\/]?(?:#|ext\\.?|extension|x)[\\-\\.\\ \\\\/]?(\\d+))?$');
        var integerRegex = new RegExp('(-)?(\\d){1,19}$');
        var websiteRegex = new RegExp('^(https?:\\/\\/)?(www\\.)?([-\\w]+\\.)+[ââ\\w]{2,63}\\/?$');
        var postalRegex = new RegExp('^[\\w- ]*$');
        var passwordComplexityRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[^ ]{8,}$');
        var evilCharRegex = new RegExp('.*[:|;|(|)|=|*|%|<|>|\\[|\\]].*');

        switch(input.id) {
          case "email":
              return emailRegex.test(input.value) ? true : false;
              break;
          case "firstName":
              return alphaNumericRegex.test(input.value) ? true : false;
              break;
          case "lastName":
              return alphaNumericRegex.test(input.value) ? true : false;
              break;
          case "company":
              return alphaNumericRegex.test(input.value) ? true : false;
              break;
          case "zip":
              return postalRegex.test(input.value) ? true : false;
              break;
          case "country":
              return alphaNumericRegex.test(input.value) ? true : false;
              break;
          case "employees":
              return integerRegex.test(input.value) ? true : false;
              break;
          case "website":
              return websiteRegex.test(input.value) ? true : false;
              break;
          case "phone":
              return phoneRegex.test(input.value) ? true : false;
              break;
          case "password":
              var check = false;
              check = input.value != "" ? true : false;
              console.log(check);
              return check;
              break;
          case "currentPassword":
              var check = false;
              check = input.value != "" ? true : false;
              return check;
              break;
          case "newPassword":
              var check = false;
              check = passwordComplexityRegex.test(input.value) && !evilCharRegex.test(input.value) ? true : false;
              return check;
              break;
          case "passwordConfirm":
              var password = document.getElementById("password");
              return password.value === input.value ? true : false;
              break;
          case "confirmNewPassword":
              var newPassword = document.getElementById("newPassword");
              return newPassword.value === input.value ? true : false;
              break;
          default:
              return false;
        }
        return false;
    }
});


