module.exports={
    "global": {
        "type": "classname",
        "category": "text-color"
    },
    "props": {
        "TEXT-WHITE": {
            "value": "'text--white'",
            "comment": "#fff"
        },
        "TEXT-BERMUDA-GRAY": {
            "value": "'text--bermuda-gray'",
            "comment": "#7094AA"
        },
        "TEXT-HEATHER": {
            "value": "'text--heather'",
            "comment": "#A5BBC3"
        },
        "TEXT-PICKLED-BLUEWOOD": {
            "value": "'text--pickled-bluewood'",
            "comment": "#263746"
        },
        "TEXT-MADISON": {
            "value": "'text--madison'",
            "comment": "#2E4355"
        },
        "TEXT-PACIFIC-BLUE": {
            "comment": "#0082C9",
            "value": "'text--pacific-blue'"
        },
        "TEXT-PING-RED": {
            "value": "'text--ping-red'",
            "comment": "#B8232F"
        }
    }
}
