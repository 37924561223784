"use strict";

export default class Background {
    constructor(backgroundContainer) {
        this.container = backgroundContainer;
        this.image = this.container.getElementsByClassName("background__image")[
            0
        ];
        this.color = this.container.getElementsByClassName("background__color")[
            0
        ];
        this.content = this.container.getElementsByClassName(
            "background__content"
        )[0];
        this.video = this.container.getElementsByClassName("video__container")[
            0
        ] || null;
        this.resizeBG = this.resizeBG.bind(this);
        
        // Disabling BG Autoplay
        //this._initVideo = this._initVideo.bind(this);

        let imageStyle = getComputedStyle(this.image);
        let colorStyle = getComputedStyle(this.color);
        this.imageInitStyle = {
            left: parseInt(imageStyle.left),
            right: parseInt(imageStyle.right)
        };
        this.colorInitStyle = {
            left: parseInt(colorStyle.left),
            right: parseInt(colorStyle.right)
        };

        this.resizeBG();
        this.video && this._initVideo(this.video);
        this.listener = window.addEventListener("resize", this.resizeBG);
        window.setTimeout(() => this.container.style.visibility = "visible", 0);
    }

    _getContainerMargin() {
        let width = $(this.container).width();
        let docWidth = $( document ).width();

        return -parseInt((docWidth - width) / 2) - 1 + "px";
    }

    _initVideo(video) {
    	// disabled for background...
    	
        //let vid = video.getElementsByTagName("video")[0];
        //vid.controls = false;
        //vid.muted = false;
        //vid.autoplay = true;
        //video.setAttribute('autoplay', 'autoplay');
        //console.log('BG autoplaying video : ' + video.getElementsByTagName("video")[0]);
        
        //vid.loop = true;
    }

    _setMargins(el, init) {
        if (init.left === 0) {
            el.style.left = this._getContainerMargin();
        }
        if (init.right === 0) {
            el.style.right = this._getContainerMargin();
        }
    }

    resizeBG() {
        this._setMargins(this.image, this.imageInitStyle);
        this._setMargins(this.color, this.colorInitStyle);
    }
}
