import ResourceCard from "../components/ResourceCard.js";
import ProductReleaseCard from "../components/ProductReleaseCard.js";
import {props as bgColors} from "../../../../../../../../../../../../../node_modules/@pingux/marketing/styles/design-tokens/classnames/background-colors.json";
import {props as textColors} from "../../../../../../../../../../../../../node_modules/@pingux/marketing/styles/design-tokens/classnames/text-colors.json";
import {props as buttonStyles} from "../../../../../../../../../../../../../node_modules/@pingux/marketing/styles/design-tokens/classnames/buttons.json";
import {props as alignment} from "../../../../../../../../../../../../../node_modules/@pingux/marketing/styles/design-tokens/classnames/alignment.json";
import pluralize from "pluralize";

import _ from "underscore";

function stripQuotes(string) {
    return string.replace(/'/g,"");
}
pluralize.addIrregularRule('misc', 'misc');
pluralize.addIrregularRule('all', 'all');
pluralize.addIrregularRule('messaging', 'messaging');
pluralize.addIrregularRule('competitive content', 'competitive content');
pluralize.addIrregularRule('opportunity qualification', 'opportunity qualification');
pluralize.addIrregularRule('deck library', 'deck library');

pluralize.addIrregularRule('global', 'global');
pluralize.addIrregularRule('north america', 'north america');
pluralize.addIrregularRule('europe', 'europe');
pluralize.addIrregularRule('africa', 'africa');
pluralize.addIrregularRule('asia', 'asia');

pluralize.addIrregularRule('pingfederate', 'pingfederate');
pluralize.addIrregularRule('pingaccess', 'pingaccess');
pluralize.addIrregularRule('pingone', 'pingone');
pluralize.addIrregularRule('ping data governance', 'ping data governance');
pluralize.addIrregularRule('pingid', 'pingid');

function Row(p = {}) {
    const row = document.createElement("div");
    const props = _.defaults(p, {
        className: "",
        align: null,
        children: null
    });

    const className = `search__results-row`;

    row.className = `${className} ${props.className}` ;
    if (props.children) {
        row.appendChild(props.children);
    }
    return row;
}

function Button(p = {}) {
    const button = document.createElement("button");
    const props = _.defaults(p, {
        className: stripQuotes(buttonStyles["BUTTON-PRIMARY"].value),
        text: null,
        style: null,
        onClick: _.noop()
    });
    button.className = `button ${props.className}`;
    if (props.style) {
        button.style = props.style;
    }
    button.innerHTML = props.text;
    button.onclick = props.onClick;

    return button;
}

function Spinner() {
    const spinner = document.createElement("div");

    spinner.className = "spinner large";
    const spinnerContainer = Row({className: stripQuotes(alignment["align-center"].value), className: "spinner-container", children: spinner })

    return spinnerContainer;
}

class PingSearchRow {
    constructor(props) {
        this.showSpinner = this.showSpinner.bind(this);
        this.hideSpinner = this.hideSpinner.bind(this);
        this.renderResults = this.renderResults.bind(this);
        this.props = _.defaults(props, {
            className: "",
            styles: {
                bgColor: null,
                titleColor: null,
                loadMoreStyle: null,
                filterStyle: null
            },
            rowClassName: "card__results-row",
            title: null,
            hasMore: null,
            results: null,
            filter: null,
            loadMore: null,
            getType: _.noop(),
            component: this.getComponentType(props)
        });

        this.section = document.createElement("div");
        this.section.className = `${this.props.className} ${this.props.styles.bgColor}`;

        this.container = document.createElement("div");
        this.container.className = "container";

        this.row = Row({className: "card__results-row"});

        this.spinner = Spinner();

        this.viewAllText = props.viewAllText;

        if (this.props.filter && this.props.hasMore) {
            this.filterButton = document.createElement("span");
            this.filterButton.className = `search-results__filter-button-container`;
            if(props.path.includes("/resources/product-releases")) {
            	this.filterButtonInner = Button({
                    onClick: this.props.filter,
                    text: `${this.viewAllText}`,
                    className: `search-results__filter-button ${this.props.styles.filterStyle}`
            	});
            } else {
            	this.filterButtonInner = Button({
                    onClick: this.props.filter,
                    text: `${this.viewAllText} <span class="search-results__filter-button--props-title">${(this.props.pluralizeActive) ? pluralize(this.props.title) : this.props.title}<span>`,
                    className: `search-results__filter-button ${this.props.styles.filterStyle}`
            	});
            }
            this.filterButton.appendChild(this.filterButtonInner);
        }

        if (this.props.loadMore && this.props.hasMore) {
            this.loadMoreButton = Row({
                className: "search-results__load-button-row",
                children: Button({
                    onClick: this.props.loadMore,
                    text: this.props.loadMoreText,
                    className: this.props.styles.loadMoreStyle
                })
            });
        }


        if (this.props.title) {
            const titleNode = document.createElement("h2");
            //lowercase the title, replace spaces with -, remove trailing s if plural
            const iconClassName = "ricon-" + pluralize.singular(this.props.assetKey.toLowerCase().replace(/ /g, "-"));
            const icon = document.createElement("i");
            icon.className = iconClassName;
            icon.style["margin-right"] = "0.5em";
            if(props.path.includes("/resources/product-releases")) {
            	titleNode.className = `search-results__title productRelease-heading-title`;
            } else {
            	titleNode.className = `search-results__title ${this.props.styles.titleColor}`;
            }
            titleNode.style.width = "100%";

            if(props.path.includes("/resources/product-releases")) {
            	titleNode.innerHTML = this.props.title;
            } else {
            	titleNode.innerHTML = (this.props.pluralizeActive) ? pluralize(this.props.title) : this.props.title;
            }

            titleNode.insertBefore(icon, titleNode.childNodes[0]);

            if (this.filterButton) {
                titleNode.appendChild(this.filterButton);
            }

            this.row.appendChild(titleNode);
        }


        this.container.appendChild(this.row);
        this.section.appendChild(this.container);

        this.renderResults(this.props.results, (this.props.hasMore && !this.filterButton));
    }



    renderResults(results, hasMore) {
        _.each(results, (result) => {
        	    this.row.appendChild(this.props.component({
	                heading: result.pageName || "",
	                text: result.pageDescription || "",
	                postDate : result.postDate || "",
	                buttonLabel : result.buttonLabel || "",
	                link: result.pagePath || "",
	                imagePath: result.imagePath || "",
	                footer: this.props.getType(result.pageTags) || ""
	            }));

        });
        if (hasMore && this.loadMoreButton && !this.row.contains(this.loadMoreButton)) {
            this.row.appendChild(this.loadMoreButton);
        }
    }
    hideLoadButton() {
        if (this.loadMoreButton && this.row.contains(this.loadMoreButton)) {
            this.row.removeChild(this.loadMoreButton);
        }
    }

    showSpinner() {
        if (!this.row.contains(this.spinner)) {
            this.row.appendChild(this.spinner);
        }
    }

    hideSpinner() {
        if (this.row.contains(this.spinner)) {
            this.row.removeChild(this.spinner);
        }
    }

    getComponentType(props) {
    		let componentType = ResourceCard;

    		if(props.path.includes("/resources/product-releases")) {
    			componentType = ProductReleaseCard;
    		}
    		return componentType;
    }
}

class PingSearchResults {
    constructor(id) {
        this.showSpinner = this.showSpinner.bind(this);
        this.hideSpinner = this.hideSpinner.bind(this);
        this.buildResultsRow = this.buildResultsRow.bind(this);
        this.clear = this.clear.bind(this);

        this.node = document.getElementById(id);
        this.node.className = "search-results"

        this.spinner = Spinner();

        this.rows = [];
    }

    clear() {
        this.node.innerHTML = "";
        this.rows = [];
    }
    //Build a results row and pass the instance back to the searchbar
    buildResultsRow(props) {
        let row = new PingSearchRow(props);
        //append the section to the node
        this.node.appendChild(row.section);
        this.rows.push(row);
        return row;
    }
    showSpinner() {
        if (!this.node.contains(this.spinner)) {
            this.node.appendChild(this.spinner);
        }
    }
    hideSpinner() {
        if (this.node.contains(this.spinner)) {
            this.node.removeChild(this.spinner);
        }
    }
}
window.PingSearchResults = PingSearchResults;
