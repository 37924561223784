module.exports={
    "global": {
        "type": "classname",
        "category": "bg-color"
    },
    "props": {
        "BG-WHITE": {
            "value": "'bg--white'",
            "comment": "#fff"
        },
        "BG-BERMUDA-GRAY": {
            "value": "'bg--bermuda-gray'",
            "comment": "#7094AA"
        },
        "BG-PICKLED-BLUEWOOD": {
            "value": "'bg--pickled-bluewood'",
            "comment": "#263746"
        },
        "BG-PATTENS-BLUE": {
            "value": "'bg--pattens-blue'",
            "comment": "#EBF4F7"
        },
        "BG-PORCELAIN": {
            "value": "'bg--porcelain'",
            "comment": "#F7F8F9"
        },
        "BG-WHITE-SMOKE": {
            "value": "'bg--white-smoke'",
            "comment": "#EDEDED"
        },
        "BG-MADISON": {
            "value": "'bg--madison'",
            "comment": "#2E4355"
        },
    }
}