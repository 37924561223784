"use strict";
export default class InteractiveMap {
    constructor (elementIDs) {
        // Bind callbacks to 'this' so callbacks have correct 'this' context
        // Done here in constructor so it is only bound ONCE, rather than per call
        this.handleInteractionTrigger = this.handleInteractionTrigger.bind(this);
        this.handleInView = this.handleInView.bind(this);

        // Constants
        this.uuid = elementIDs.uuid;
        this.element = elementIDs.container;
        //Initialize Expand/Collapse controls on top for non-mobile devices
        let interactiveMap = document.getElementsByClassName("interactive__map--container");

        this.element = interactiveMap[0];
        
        
        window.onscroll = this.handleInteractionTrigger;
        
    }
    
    handleInteractionTrigger () {
	  // is element in view?
	  if (this.handleInView()) {
	      // element is in view, add class to element
		  this.element.classList.add('animate');
	  }
    }
    
    
    handleInView() {
    	
	  let elementHeight = this.element.clientHeight;
  	  // get window height
  	  let windowHeight = window.innerHeight;
  	  // get number of pixels that the document is scrolled
  	  let scrollY = window.scrollY || window.pageYOffset;
  	  
  	  // get current scroll position (distance from the top of the page to the bottom of the current viewport)
  	  let scrollPosition = scrollY + windowHeight;
  	  // get element position (distance from the top of the page to the bottom of the element)
  	  let elementPosition = this.element.getBoundingClientRect().top + scrollY + elementHeight;
  	  // is scroll position greater than element position? (is element in view?)
  	  if (scrollPosition > (elementPosition - (elementPosition/2))) {
  	    return true;
  	  }
  	  
  	  return false;
  }
}
window.InteractiveMap = InteractiveMap;   // Expose to be accessible globally