"use strict";

export default class Table {
    constructor (elementIDs, rows, cols) {
        // Bind callbacks to 'this' so callbacks have correct 'this' context
        // Done here in constructor so it is only bound ONCE, rather than per call
        this._handleRowToggle = this._handleRowToggle.bind(this);

        // Bind necessary DOM elements to class
        this.node = document.getElementById(elementIDs.container);

        // Constants
        this.NUM_ROWS = rows;
        this.NUM_COLS = cols;

        // Bind callbacks to elements
        for (var i=1; i <= this.NUM_ROWS; i++) {
            this.initRow("row-" + i);
        }
    }

    initRow (rowId) {
        let row = this.node.querySelector("[data-row-id='" + rowId + "']");
        if (row && row.dataset.rowExpandable == "true") {
            row.onclick = this._handleRowToggle;
        }
    }

    _handleRowToggle (e) {
        e.preventDefault();
        $(e.currentTarget).toggleClass("table__row--expanded");
    }
}
window.Table = Table;   // Expose to be accessible globally