export default function ResourceCard (props) {
    const card = document.createElement("div");
    card.className = "card card--resource-card card--gray-bg";
    card.onclick = () => window.open(props.link, "_top");
	    if(props.footer == "Success Story") {
	    		card.innerHTML = `
	            <div class="card__body">
	                <div style="text-align:center;">
	                		<img class="Resource" width="100%" style="text-align: center;" src="${props.imagePath}">
	                </div>
	            </div>
	            <div class="card__footer">
	                <h6>${props.footer}</h6>
	            </div>
	        `;
	        return card;
    } else {
    		card.innerHTML = `
            <div class="card__body">
                <h6 class="card--resource-card__heading" title="${props.heading}">${props.heading}</h6>
                <p class="card--resource-card__body">${props.text}</p>
            </div>
            <div class="card__footer">
                <h6>${props.footer}</h6>
            </div>
        `;
        return card;
    	
    }
}
