module.exports={
    "global": {
        "type": "classname",
        "category": "alignment"
    },
    "props": {
        "align-left": {
            "value": "'align--left'"
        },
        "align-center": {
            "value": "'align--center'"
        },
        "align-right": {
            "value": "'align--right'"
        },
    }
}
