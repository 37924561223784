"use strict";
import Promise from "bluebird";

export default class ListSection {
    constructor (elementIDs, servletPath, itemType) {
        if (!servletPath) {
            return;
        }

        // Bind necessary DOM elements to class
        this.node = document.getElementById(elementIDs.container);
        this.items = document.getElementById(elementIDs.items);
        this.spinnerContainer = document.getElementById(elementIDs.spinnerContainer);

        // Constants
        this.SERVLET_PATH = servletPath;
        this.ITEM_TYPE = itemType;

        // Set initial state
        let initialState = {};
        this.state = initialState;

        // Fire get items
        this.doGetItems();
    }

    getCSRFToken () {
        return new Promise((resolve, reject, onCancel) => {
            let xhr = new XMLHttpRequest();
            xhr.onload = () => resolve(xhr);
            xhr.onerror = () => reject(xhr);
            xhr.open("GET", "/libs/granite/csrf/token.json", true);
            xhr.send(null);
            onCancel(() => {
                xhr.abort();
            });
        });
    }

    getItems (query, token) {
        // Use Bluebird Promise for cancel support
        return new Promise((resolve, reject, onCancel) => {
            let xhr = new XMLHttpRequest();
            xhr.onload = () => resolve(xhr);
            xhr.onerror = () => reject(xhr);
            xhr.open("GET", this.SERVLET_PATH + "?" + query, true);
            xhr.setRequestHeader("CSRF-Token", token);
            xhr.send();
            onCancel(() => {
                xhr.abort();
            });
        });
    }

    buildQuery () {
        const params = {
            type: this.ITEM_TYPE
        };

        const esc = encodeURIComponent;
        return Object.keys(params)
            .map(k => esc(k) + "=" + esc(params[k]))
            .join("&");
    }

    doGetItems () {
        this.showElement("spinnerContainer");

        return this.getCSRFToken()
            .then(response => {
                if(response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                const msg = JSON.parse(response.responseText);
                let query = this.buildQuery();
                return this.getItems(query, msg.token);
            })
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return JSON.parse(response.responseText);
            })
            .then(data => {
                this.state.response = data;
            })
            .catch((e) => {
                throw new Error(e);
            })
            .finally(() => {
                this.render(
                    this.state.response.results,
                    this.state.response.resultsTotal
                );
            });
    }

    render (results, resultsTotal) {
        // Render results
        if (results.length === 0) {
            return;
        } else {
            let frag = document.createDocumentFragment();
            results.forEach((r) => {
                $(frag).append($.parseHTML($.parseHTML(r.resultMarkup, true)[0].data));
            });
            $(this.items).append(frag);
        }

        this.hideElement("spinnerContainer");
    }

    showElement (elementKey) {
        let element = this[elementKey];
        element.classList.remove("hide");
    }
    hideElement (elementKey) {
        let element = this[elementKey];
        element.classList.add("hide");
    }
}
window.ListSection = ListSection;     // Expose to be accessible globally