"use strict";
import _ from "underscore";

export default class Pagination {
    constructor (el, callback, totalPages, currentPage) {
        // Bind necessary DOM elements to class
        this.node = el;
        if (!this.node) {
            this.node = document.createElement("ul");
        }
        $(this.node).addClass("pagination__container");

        // Constants
        this.TOTAL_PAGES = Math.round(totalPages) || 0;

        // Set initial state
        let initialState = {
            currentPage: currentPage || 1,
            callback: callback || _.noop()
        };
        this.state = initialState;

        this.initDOM(this.node);
        this.render();
    }

    initDOM (node) {
        // Bind additional DOM elements to class
        this.prev = this.createPrev();
        this.next = this.createNext();
        this.prevEllipsis = this.createEllipsis("pagination__ellipsis--prev");
        this.nextEllipsis = this.createEllipsis("pagination__ellipsis--next");

        // Append to DOM in correct order
        $(node).append(this.prev);
        $(node).append(this.prevEllipsis);
        $(node).append(this.nextEllipsis);
        $(node).append(this.next);
    }

    createPrev () {
        let prev = document.createElement("li");
        prev.className = "fa fa-angle-left pagination__prev";

        prev.onclick = (e) => {
            this.stepPrev();
        };

        return prev;
    }

    createNext () {
        let next = document.createElement("li");
        next.className = "fa fa-angle-right pagination__next";

        next.onclick = (e) => {
            this.stepNext();
        };

        return next;
    }

    createEllipsis (customClass) {
        let ellipsis = document.createElement("li");
        ellipsis.className = "pagination__ellipsis " + customClass;

        return ellipsis
    }

    createPage (p) {
        let page = document.createElement("li");
        page.setAttribute("data-pagenum", p);
        page.textContent = p;

        switch (p) {
            case 1:
                page.className = "pagination__page pagination__page--first";
                break;
            case this.TOTAL_PAGES:
                page.className = "pagination__page pagination__page--last";
                break;
            default:
                page.className = "pagination__page";
                break;
        }

        if (p === this.state.currentPage) {
            $(page).addClass("pagination__page--selected");
        }

        page.onclick = (e) => {
            let pagenum = parseInt(e.currentTarget.dataset.pagenum);
            if (this.state.currentPage !== pagenum) {
                this.setCurrentPage(pagenum);
            }
        };

        return page;
    }

    stepPrev () {
        if (this.state.currentPage > 1) {
            this.state.currentPage = this.state.currentPage - 1;
            this.state.callback(this.state.currentPage);
            this.render();
        }
    }

    stepNext () {
        if (this.state.currentPage < this.TOTAL_PAGES) {
            this.state.currentPage = this.state.currentPage + 1;
            this.state.callback(this.state.currentPage);
            this.render();
        }
    }

    setCurrentPage (pagenum) {
        this.state.currentPage = pagenum;
        this.state.callback(this.state.currentPage);
        this.render();
    }

    clearPages () {
        let pages = $(this.node).find(".pagination__page");

        for (let i=0; i < pages.length; i++) {
            $(pages[i]).remove();
        }
    }

    showPrev () {
        $(this.prev).removeClass("hide");
    }

    hidePrev () {
        $(this.prev).addClass("hide");
    }

    showNext () {
        $(this.next).removeClass("hide");
    }

    hideNext () {
        $(this.next).addClass("hide");
    }

    showPrevEllipsis () {
        $(this.prevEllipsis).removeClass("hide");
    }

    hidePrevEllipsis () {
        $(this.prevEllipsis).addClass("hide");
    }

    showNextEllipsis () {
        $(this.nextEllipsis).removeClass("hide");
    }

    hideNextEllipsis () {
        $(this.nextEllipsis).addClass("hide");
    }

    render () {
        // Clear old pages before constructing new pages state
        this.clearPages();

        if (this.TOTAL_PAGES === 0 || this.TOTAL_PAGES === 1) {
            this.hidePrev();
            this.hidePrevEllipsis();
            this.hideNextEllipsis();
            this.hideNext();
        }

        if (this.TOTAL_PAGES > 1 && this.TOTAL_PAGES <= 4) {
            this.showPrev();
            this.hidePrevEllipsis();
            this.hideNextEllipsis();
            this.showNext();

            let pages = [];
            for (let i=0; i < this.TOTAL_PAGES; i++) {
                pages[i] = this.createPage(i + 1);
            }

            $(this.prev).after(pages);
        }

        if (this.TOTAL_PAGES > 4) {
            this.showPrev();
            this.showNext();

            if (this.state.currentPage === 1 || this.state.currentPage === 2) {
                this.hidePrevEllipsis();
                this.showNextEllipsis();

                let pages = [];
                for (let i=0; i < 3; i++) {
                    pages[i] = this.createPage(i + 1);
                }

                $(this.prev).after(pages);
                $(this.next).before(this.createPage(this.TOTAL_PAGES));
            } else if (this.state.currentPage === this.TOTAL_PAGES || this.state.currentPage === (this.TOTAL_PAGES - 1)) {
                this.showPrevEllipsis();
                this.hideNextEllipsis();

                let pages = [];
                for (let i=0; i < 3; i++) {
                    pages[i] = this.createPage(this.TOTAL_PAGES - i);
                }

                $(this.next).before(pages.reverse());
                $(this.prev).after(this.createPage(1));
            } else if (this.state.currentPage !== 1 && this.state.currentPage !== this.TOTAL_PAGES) {
                this.showPrevEllipsis();
                this.showNextEllipsis();

                let pages = [];
                pages[0] = this.createPage(this.state.currentPage - 1);
                pages[1] = this.createPage(this.state.currentPage);
                pages[2] = this.createPage(this.state.currentPage + 1);

                $(this.prevEllipsis).after(pages);
                $(this.prev).after(this.createPage(1));
                $(this.next).before(this.createPage(this.TOTAL_PAGES));
            }
        }
    }
}
window.Pagination = Pagination;     // Expose to be accessible globally