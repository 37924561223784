"use strict";
import _ from "underscore";
import Promise from "bluebird";
import Pagination from "./Pagination.js";

Promise.config({
    // Enable cancellation
    cancellation: true,
    longStackTraces: true,
    warnings: true
});

const FILTER_TYPES = {
    DEFAULT: "DEFAULT",
    QUERY_PARAM: "QUERY_PARAM",
    STATE: "STATE",
    PAGINATION: "PAGINATION"
};

export default class BlogSearchbar {
    constructor (elementIDs, opts) {
        // Bind callbacks to 'this' so callbacks have correct 'this' context
        // Done here in constructor so it is only bound ONCE, rather than per call
        this._handlePagination = this._handlePagination.bind(this);

        // Bind necessary DOM elements to class
        this.node = document.getElementById(elementIDs.container);
        this.input = document.getElementById(elementIDs.input);
        this.resultsContainer = document.getElementById(elementIDs.resultsContainer);
        this.noResultsContainer = document.getElementById(elementIDs.noResultsContainer);
        this.spinnerContainer = document.getElementById(elementIDs.spinnerContainer);
        this.verticalsContainer = document.getElementById(elementIDs.verticalsContainer);
        this.sidebar = document.getElementById(elementIDs.sidebar).getElementsByClassName("blog-sidebar__container")[0];
        this.pagination = document.getElementById(elementIDs.pagination);
        // Constants
        this.MAX_COLLECTED_DOCS = 500;
        this.RESULTS_PER_PAGE = 6;
        this.DEFAULT_FILTER = {
            categories: opts.allFilters.categories ? opts.allFilters.categories.split(",") : [],
            tags: opts.allFilters.tags ? opts.allFilters.tags.split(",") : [],
                exclude: opts.allFilters.exclude ? opts.allFilters.exclude.split(",") : []
        };
        this.EMPTY_FILTER = {
            categories: [],
            tags: [],
            verticals: [],
            exclude: []
        };

        // Initialize dynamic behaviour for input/verticals/sidebar elements w/ the BlogSearchbar
        this.dynamicizeInput(this.input);
        this.dynamicizeVerticals(this.verticalsContainer);
        this.dynamicizeSidebar(this.sidebar);
        this.LANGUAGE = opts.language;

        let queryFilters = {
            categories: opts.queryFilters.categories ? opts.queryFilters.categories.split(",") : [],
            tags: opts.queryFilters.tags ? opts.queryFilters.tags.split(",") : [],
            verticals: opts.queryFilters.verticals ? opts.queryFilters.verticals.split(",") : [],
                exclude: opts.queryFilters.exclude ? opts.queryFilters.exclude.split(",") : [],
            author: opts.queryFilters.author
        };

        // Set initial state
        let initialState = {
            searchString: "",
            filters: _.isEmpty(opts.queryFilters) ? this.EMPTY_FILTER : queryFilters,
            filterType: _.isEmpty(opts.queryFilters) ? FILTER_TYPES.DEFAULT : FILTER_TYPES.QUERY_PARAM,
            exclude: _.isEmpty(opts.queryFilters.exclude) ? this.DEFAULT_FILTER.exclude : [],
            searchPromises: [],
            offset: null,
            order: "date/desc",
            currentPage: 1
        };
        this.state = initialState;
        // Fire initial search
        this.doSearch();
    }

    dynamicizeInput (inputDOM) {
        if (!inputDOM) {
            return;
        }

        inputDOM.onkeyup = (e) => {
            if (e.keyCode === 13) {
                this.state.filterType = FILTER_TYPES.STATE;
                this.doSearch();
            } else {
                this.state.searchString = e.target.value;
            }
        };
    }

    dynamicizeVerticals (verticalsContainerDOM) {
        if (!verticalsContainerDOM) {
            return;
        }

        let verticals = verticalsContainerDOM.getElementsByTagName("li");

        for (let i=0; i < verticals.length; i++) {
            verticals[i].onclick = (e) => {
                let id = e.currentTarget.dataset.id;
                let index = this.state.filters.verticals.indexOf(id);

                if (index !== -1) {
                    this.state.filters.verticals.splice(index, 1);
                } else {
                    this.state.filters.verticals.push(id);
                }

                $(e.currentTarget).toggleClass("selected");
                this.state.filterType = FILTER_TYPES.STATE;

                // Protect against quick consecutive clicks to change filter
                this.doSearchWithConsecutiveRequestManagement();
            }
        }
    }

    dynamicizeSidebar (sidebarDOM) {
        if (!sidebarDOM) {
            return;
        }

        let lists = sidebarDOM.getElementsByClassName("blog-sidebar__section-list");

        for (let i=0; i < lists.length; i++) {
            let items = lists[i].getElementsByTagName("li");

            for (let j=0; j < items.length; j++) {
                items[j].onclick = (e) => {
                    let id = e.currentTarget.dataset.id;
                    let type = e.currentTarget.dataset.type;
                    let index = this.state.filters[type].indexOf(id);

                    if (index !== -1) {
                        this.state.filters[type].splice(index, 1);
                    } else {
                        this.state.filters[type].push(id);
                    }

                    $(e.currentTarget).toggleClass("selected");
                    this.state.filterType = FILTER_TYPES.STATE;

                    // Protect against quick consecutive clicks to change filter
                    this.doSearchWithConsecutiveRequestManagement();
                }
            }
        }
    }

    getCSRFToken () {
        return new Promise((resolve, reject, onCancel) => {
            let xhr = new XMLHttpRequest();
            xhr.onload = () => resolve(xhr);
            xhr.onerror = () => reject(xhr);
            xhr.open("GET", "/libs/granite/csrf/token.json", true);
            xhr.send(null);
            onCancel(() => {
                xhr.abort();
            });
        });
    }

    search (query, token) {
        // Use Bluebird Promise for cancel support
        return new Promise((resolve, reject, onCancel) => {
            let xhr = new XMLHttpRequest();
            xhr.onload = () => resolve(xhr);
            xhr.onerror = () => reject(xhr);
            xhr.open("GET", '/bin/ping/blogSearch?' + query, true);
            xhr.setRequestHeader("CSRF-Token", token);
            xhr.send();
            onCancel(() => {
                xhr.abort();
            });
        });
    }

    buildQuery (keywords, categories, tags, verticals, exclude, author, offset, order) {
            const params = {
            keywords: keywords,
            tags1: categories,
            tags2: tags,
            tags3: verticals,
            exclude: exclude,
            author: author,
            language : this.LANGUAGE,

            // These work w/ pagination
            limit: this.RESULTS_PER_PAGE,
            offset: offset,
            order: order
        };

        // Strip out empty/null/undefined params
        let strippedParams = {};
        for (let key in params) {
            let p = params[key];
            if (!_.isEmpty(p) || _.isNumber(p)) {
                strippedParams[key] = p;
            }
        }

        // If no filters, fill in w/ DEFAULT filters to get back all results
        if (!strippedParams.keywords && !strippedParams.tags1 && !strippedParams.tags2 && !strippedParams.tags3 && !strippedParams.author) {


            this.state.filters = this.EMPTY_FILTER;

            strippedParams.tags1 = this.DEFAULT_FILTER.categories.join(",");
            strippedParams.tags2 = this.DEFAULT_FILTER.tags.join(",");
            strippedParams.order = "date/desc";
        }
        const esc = encodeURIComponent;
        return Object.keys(strippedParams)
            .map(k => esc(k) + "=" + esc(strippedParams[k]))
            .join("&");
    }

    doSearch () {
        this.resetResultsContainer();
        this.showSpinner();

        // Reset currentPage for default results on non-pagination state changes
        if (this.state.filterType !== FILTER_TYPES.PAGINATION) {
            this.state.currentPage = 1;
        }

        return this.getCSRFToken()
            .then(response => {
                if(response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                const msg = JSON.parse(response.responseText);
                let query = this.buildQuery(
                    this.state.searchString,
                    this.state.filters.categories.join(","),
                    this.state.filters.tags.join(","),
                    this.state.filters.verticals.join(","),
                    this.state.exclude.join(","),
                    this.state.filters.author,
                    this.state.filterType === FILTER_TYPES.PAGINATION ? (this.state.currentPage - 1) * this.RESULTS_PER_PAGE : null,
                    this.state.order
                );
                return this.search(query, msg.token);
            })
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return JSON.parse(response.responseText);
            })
            .then(data => {
                this.state.searchResponse = data;
            })
            .catch(function (e) {
                throw new Error(e);
            })
            .finally(() => {
                this.render(
                    this.state.searchResponse.results,
                    this.state.searchResponse.resultsTotal,
                    this.state.filters,
                    this.state.filterType
                );
            });
    }

    doSearchWithConsecutiveRequestManagement () {
        // Manage filter request promises to only fire off the last request if user
        //  quickly triggers multiple filters consecutively
        this.state.searchPromises.forEach((p) => {
            p.cancel();
        });
        this.state.searchPromises = [];

        let newPromise = this.doSearch();
        newPromise.then(() => {
            this.state.searchPromises.splice(this.state.searchPromises.indexOf(newPromise), 1);
        });
        this.state.searchPromises.push(newPromise);
    }

    styleSelected (elements, selected) {
        for (let i=0; i < elements.length; i++) {
            if (selected.includes(elements[i].dataset.id)) {
                $(elements[i]).addClass("selected");
            }
        }
    }

    showSpinner () {
        $(this.spinnerContainer).removeClass("hide");
    }

    hideSpinner () {
        $(this.spinnerContainer).addClass("hide");
    }

    showNoResultsMsg () {
        $(this.noResultsContainer).removeClass("hide");
    }

    hideNoResultsMsg () {
        $(this.noResultsContainer).addClass("hide");
    }

    resetResultsContainer () {
        $(this.resultsContainer).empty();
        this.hideSpinner();
        this.hideNoResultsMsg();
        $(this.resultsContainer).append(this.noResultsContainer);
        $(this.resultsContainer).append(this.spinnerContainer);
    }

    _handlePagination (page) {
        this.state.filterType = FILTER_TYPES.PAGINATION;
        this.state.currentPage = page;

        this.doSearchWithConsecutiveRequestManagement();
    }

    _parseResultMarkup (resultMarkup) {
        // Check if ellipsis client-libs script/link tag already exists on document & remove from result markup if it does
        if (document.querySelector("script[src^='/etc.clientlibs/settings/wcm/designs/pic6/clientlibs-ellipsis']")) {
            resultMarkup = resultMarkup.replace('<script type="text/javascript" src="/etc.clientlibs/settings/wcm/designs/pic6/clientlibs-ellipsis.js"></script>', "");
        }

        if (document.querySelector("link[href^='/etc.clientlibs/settings/wcm/designs/pic6/clientlibs-ellipsis']")) {
            resultMarkup = resultMarkup.replace('<link rel="stylesheet" href="/etc.clientlibs/settings/wcm/designs/pic6/clientlibs-ellipsis.css" type="text/css">', "");
        }

        return resultMarkup;
    }

    render (searchResults, totalResults, filters, filterType) {
        this.hideSpinner();
        // Render results previews or no results message
        if (searchResults.length === 0) {
            this.showNoResultsMsg();
        } else {
            searchResults.forEach((r) => {
                $(this.resultsContainer).append($.parseHTML(this._parseResultMarkup(r.resultMarkup), true));
            });
        }

        // Render pagination; do NOT re-initialize if updates triggered by pagination callback
        if (this.state.filterType !== FILTER_TYPES.PAGINATION) {
            $(this.pagination).empty();
            new Pagination(this.pagination, this._handlePagination, Math.min((this.MAX_COLLECTED_DOCS / this.RESULTS_PER_PAGE), Math.ceil(totalResults / this.RESULTS_PER_PAGE)), this.state.currentPage);
        }
        $(this.resultsContainer).append(this.pagination);


        // Style any initial QUERY_PARAM filters
        if (filterType === FILTER_TYPES.QUERY_PARAM) {
            if (!_.isEmpty(filters.categories)) {
                let categories = $(this.sidebar)
                    .find("#blog-sidebar__categories")
                    .find("li");

                this.styleSelected(categories, filters.categories);
            }
            if (!_.isEmpty(filters.tags)) {
                let tags = $(this.sidebar)
                    .find("#blog-sidebar__tags")
                    .find("li");

                this.styleSelected(tags, filters.tags);
            }
            if (!_.isEmpty(filters.verticals)) {
                let verticals = $(this.verticalsContainer).find("li");

                this.styleSelected(verticals, filters.verticals);
            }
        }
    }
}
window.BlogSearchbar = BlogSearchbar;   // Expose to be accessible globally