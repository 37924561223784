"use strict";
import _ from "underscore";
import Promise from "bluebird";
import { callIfOutsideOfContainer } from "../utils/EventUtils.js";

Promise.config({
    // Enable cancellation
    cancellation: true,
    longStackTraces: true,
    warnings: true
});

const FILTER_TYPES = {
    DEFAULT: "DEFAULT",
    STATE: "STATE",
    LOAD_MORE: "LOAD_MORE"
};

export default class FilterSearchbar {
    constructor (elementIDs, opts) {
        // Bind necessary DOM elements to class
        this.node = document.getElementById(elementIDs.container);
        this.searchbar = document.getElementById(elementIDs.searchbar);
        this.resultsContainer = document.getElementById(elementIDs.resultsContainer);
        this.searchResults = document.getElementById(elementIDs.searchResults);
        this.loadMoreContainer = document.getElementById(elementIDs.loadMoreContainer);
        this.noResultsContainer = document.getElementById(elementIDs.noResultsContainer);
        this.spinnerContainer = document.getElementById(elementIDs.spinnerContainer);
        this.clearFiltersContainer = document.getElementById(elementIDs.clearFiltersContainer);

        // Hold filter lists' DOMs in object map for easy access when modifying state
        let filterLists = this.getFilterLists(this.searchbar);

        // Constants
        this.MAX_RESULTS_PER_LOAD = 12;
        this.ALL_FILTERS = this.getAllFilters(opts.filters);
        this.SEARCH_PATH = opts.searchPath;
        this.LANGUAGE = opts.language;
        
        let queryFilters = {
    		filter1: opts.queryFilters.industry ? opts.queryFilters.industry.split(",") : [],
    		filter2: opts.queryFilters.identityType ? opts.queryFilters.identityType.split(",") : [],
        };       
        
        // Initialize dynamic behaviour for filter dropdowns, clear & load more buttons w/ the FilterSearchbar
        this.dynamicizeTriggers(this.searchbar);
        this.dynamicizeFilters(filterLists);
        this.dynamicizeClearFilters(this.clearFiltersContainer);
        this.dynamicizeLoadMore(this.loadMoreContainer);
        
        // Set initial state
        let initialState = {
            filterLists: filterLists,
            filters: _.isEmpty(opts.queryFilters) ? this.resetFilters() : queryFilters,
            filterType: _.isEmpty(opts.queryFilters) ? FILTER_TYPES.DEFAULT : FILTER_TYPES.STATE,
            searchPromises: [],
            offset: 0,
            order: "alpha/desc",
            lastActiveTrigger: null,
        };
        this.state = initialState;

        // Style selected for initial load
        for (let filterId in filterLists) {   
            this.styleSelectAll(filterId);
        }
        
    	if (!_.isEmpty(opts.queryFilters)) {
    		if (queryFilters.filter1.length!=0) {
    			this.styleDeselectAll('filter1');
    			this.queryStyleSelect('filter1', queryFilters.filter1);
    		}
    		
    		if (queryFilters.filter2.length!=0) {
    			this.styleDeselectAll('filter2');
    			this.queryStyleSelect('filter2', queryFilters.filter2);
    		}
    	}        	        

        // Add event listener to toggle dropdowns when click outside current open dropdown on mobile view
        document.addEventListener("click", (e) => {
            if (this.state.lastActiveTrigger) {
                callIfOutsideOfContainer(this.state.lastActiveTrigger, (e) => {
                    $(this.state.lastActiveTrigger).removeClass("mobile-open");
                }, e);
            }
        });

        // Fire initial search
        this.doSearch();
    }

    getAllFilters (filters) {
        let allFilters = {};
        let parsedFilters = filters.replace("[", "").replace(", %]", "").split(", %, ");

        parsedFilters.forEach((filter, i) => {
            allFilters["filter" + (i + 1)] = filter.split(",");
        });

        return allFilters;
    }

    getFilterLists (searchbarDOM) {
        if (!searchbarDOM) {
            return;
        }

        let filterLists = {};
        let filterDropdowns = searchbarDOM.getElementsByClassName("filter-dropdown");

        for (let i=0; i < filterDropdowns.length; i++) {
            let list = filterLists["filter" + (i + 1)] = [];
            let filterList = filterDropdowns[i].getElementsByClassName("filter-dropdown__list")[0];
            let filters = filterList.getElementsByTagName("li");

            for (let v=0; v < filters.length; v++) {
                list[v] = filters[v];
            }
        }

        return filterLists;
    }

    resetFilters () {
        let resetFilters = {};

        for (let filterId in this.ALL_FILTERS) {
            resetFilters[filterId] = [];
        }

        return resetFilters;
    }

    dynamicizeTriggers (searchbarDOM) {
        if (!searchbarDOM) {
            return;
        }

        let filterDropdowns = searchbarDOM.getElementsByClassName("filter-dropdown");

        for (let i=0; i < filterDropdowns.length; i++) {
            filterDropdowns[i].onclick = (e) => {
                e.preventDefault();

                let filterListContainerDOM = $(e.currentTarget).find(".filter-dropdown__list")[0];
                callIfOutsideOfContainer(filterListContainerDOM, (e) => {
                    $(e.currentTarget).toggleClass("mobile-open");
                }, e);

                if (!$(e.currentTarget).is(this.state.lastActiveTrigger)) {
                    $(this.state.lastActiveTrigger).removeClass("mobile-open");
                }

                this.state.lastActiveTrigger = filterDropdowns[i];
            }
        }
    }
    
    dynamicizeFilters (filterListsDOM) {
        if (!filterListsDOM) {
            return;
        }

        for (let filterId in filterListsDOM) {
            filterListsDOM[filterId].forEach((item, i) => {
                let id = item.dataset.id;

                if (id === "all-selector") {
                    item.onclick = (e) => {
                        e.preventDefault();

                        let id = e.currentTarget.dataset.id;
                        let filterId = e.currentTarget.dataset.filterId;
                        let input = $(e.currentTarget).find("input")[0];

                        if (input.checked) {
                            this.deselectAll(filterId);
                        } else {
                            this.selectAll(filterId);
                        }

                        this.state.filterType = FILTER_TYPES.STATE;
                        this.state.offset = 0;

                        // Protect against quick consecutive clicks to change filter
                        this.doSearchWithConsecutiveRequestManagement();
                    }
                } else {
                    item.onclick = (e) => {
                        e.preventDefault();

                        let id = e.currentTarget.dataset.id;
                        let filterId = e.currentTarget.dataset.filterId;
                        let index = this.state.filters[filterId].indexOf(id);
                        let input = $(e.currentTarget).find("input")[0];

                        if (input.checked) {
                            if ($($(e.currentTarget).find("label")).hasClass("disabled")) {
                                this.styleDeselectAll(filterId);

                                input.checked = true;
                                this.state.filters[filterId].push(id);
                            } else {
                                input.checked = false;
                                this.state.filters[filterId].splice(index, 1);

                                // Re-apply all filters under this list when none selected
                                if (this.state.filters[filterId].length === 0) {
                                    this.selectAll(filterId);
                                }
                            }
                        } else {
                            input.checked = true;
                            this.state.filters[filterId].push(id);
                        }

                        this.state.filterType = FILTER_TYPES.STATE;
                        this.state.offset = 0;
                        
                        // Protect against quick consecutive clicks to change filter
                        this.doSearchWithConsecutiveRequestManagement();
                    }
                }
            });
        }
    }

    dynamicizeClearFilters (clearFiltersDOM) {
        if (!clearFiltersDOM) {
            return;
        }

        // Reset all filters to the default initial onload values
        clearFiltersDOM.onclick = (e) => {
            for (let filterId in this.state.filterLists) {
                this.styleSelectAll(filterId);
            }

            this.state.filters = this.resetFilters();
            this.state.filterType = FILTER_TYPES.DEFAULT;
            this.state.offset = 0;

            // Protect against quick consecutive clicks to change filter
            this.doSearchWithConsecutiveRequestManagement();
        }
    }

    dynamicizeLoadMore (loadMoreDOM) {
        if (!loadMoreDOM) {
            return;
        }

        loadMoreDOM.onclick = (e) => {
            // Reset offset if previous filter trigger was not the load more button
            if (this.state.filterType !== FILTER_TYPES.LOAD_MORE) {
                this.state.offset = 1;
            } else {
                this.state.offset = this.state.offset + 1;
            }

            this.state.filterType = FILTER_TYPES.LOAD_MORE;

            // Protect against quick consecutive clicks to change filter
            this.doSearchWithConsecutiveRequestManagement();
        }
    }
    
    queryStyleSelect (filterId, queryList) {
    	for(var i = 0; i < queryList.length; i++) {
    		this.state.filterLists[filterId].forEach((item) => {        	
        		if (item.dataset.id == queryList[i]) {
        			let input = $(item).find("input")[0];
                    input.checked = true;        			
        		}
        	});
		}        	
    }    

    styleSelectAll (filterId) {
        this.state.filterLists[filterId].forEach((item) => {
            let input = $(item).find("input")[0];
            input.checked = true;

            if (item.dataset.id !== "all-selector") {
                let label = $(item).find(".filter-dropdown__checkbox")[0];
                $(label).addClass("disabled");
            }
        });
    }

    styleDeselectAll (filterId) {
        this.state.filterLists[filterId].forEach((item) => {
            let input = $(item).find("input")[0];
            input.checked = false;

            if (item.dataset.id !== "all-selector") {
                let label = $(item).find(".filter-dropdown__checkbox")[0];
                $(label).removeClass("disabled");
            }
        });
    }

    selectAll (filterId) {
        this.styleSelectAll(filterId);
        this.state.filters[filterId] = [];
    }

    deselectAll (filterId) {
        this.styleDeselectAll(filterId);
        this.state.filters[filterId] = [];
    }

    getCSRFToken () {
        return new Promise((resolve, reject, onCancel) => {
            let xhr = new XMLHttpRequest();
            xhr.onload = () => resolve(xhr);
            xhr.onerror = () => reject(xhr);
            xhr.open("GET", "/libs/granite/csrf/token.json", true);
            xhr.send(null);
            onCancel(() => {
                xhr.abort();
            });
        });
    }

    search (query, token) {
        // Use Bluebird Promise for cancel support
        return new Promise((resolve, reject, onCancel) => {
            let xhr = new XMLHttpRequest();
            xhr.onload = () => resolve(xhr);
            xhr.onerror = () => reject(xhr);
            xhr.open("GET", this.SEARCH_PATH + "?" + query, true);
            xhr.setRequestHeader("CSRF-Token", token);
            xhr.send();
            onCancel(() => {
                xhr.abort();
            });
        });
    }

    buildQuery (tags1, tags2, tags3, offset, order) {
        const params = {
            tags1: tags1,
            tags2: tags2,
            tags3: tags3,
            language: this.LANGUAGE,

            // These work w/ load more
            limit: this.MAX_RESULTS_PER_LOAD,
            offset: offset,
            order: order
        };

        // Strip out empty/null/undefined params
        let strippedParams = {};
        for (let key in params) {
            let p = params[key];
            if (!_.isEmpty(p) || _.isNumber(p)) {
                strippedParams[key] = p;
            }
        }

        // If no filters, fill in w/ DEFAULT filters to get back all results
        if (!strippedParams.tags1 && !strippedParams.tags2 && !strippedParams.tags3) {

            this.state.filters = this.resetFilters();

            if (this.ALL_FILTERS.filter1) {
                strippedParams.tags1 = this.ALL_FILTERS.filter1.join(",");
            }
            if (this.ALL_FILTERS.filter2) {
                strippedParams.tags2 = this.ALL_FILTERS.filter2.join(",");
            }
            if (this.ALL_FILTERS.filter3) {
                strippedParams.tags3 = this.ALL_FILTERS.filter3.join(",");
            }
        }

        const esc = encodeURIComponent;
        return Object.keys(strippedParams)
            .map(k => esc(k) + "=" + esc(strippedParams[k]))
            .join("&");
    }

    doSearch () {
        this.resetResultsContainer();
        this.showSpinner();

        return this.getCSRFToken()
            .then(response => {
                if(response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                const msg = JSON.parse(response.responseText);
                let query = this.buildQuery(
                    this.state.filters.filter1 ? this.state.filters.filter1.join(",") : [],
                    this.state.filters.filter2 ? this.state.filters.filter2.join(",") : [],
                    this.state.filters.filter3 ? this.state.filters.filter3.join(",") : [],
                    this.state.filterType === FILTER_TYPES.LOAD_MORE ? this.state.offset * this.MAX_RESULTS_PER_LOAD : null,
                    this.state.order
                );
                return this.search(query, msg.token);
            })
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return JSON.parse(response.responseText);
            })
            .then(data => {
                this.state.searchResponse = data;
            })
            .catch(function (e) {
                throw new Error(e);
            })
            .finally(() => {
                this.render(
                    this.state.searchResponse.results,
                    this.state.searchResponse.resultsTotal,
                    this.state.filters,
                    this.state.filterType
                );
            });
    }

    doSearchWithConsecutiveRequestManagement () {
        // Manage filter request promises to only fire off the last request if user
        //  quickly triggers multiple filters consecutively
        this.state.searchPromises.forEach((p) => {
            p.cancel();
        });
        this.state.searchPromises = [];

        let newPromise = this.doSearch();
        newPromise.then(() => {
            this.state.searchPromises.splice(this.state.searchPromises.indexOf(newPromise), 1);
        });
        this.state.searchPromises.push(newPromise);
    }

    showSpinner () {
        $(this.spinnerContainer).removeClass("hide");
    }

    hideSpinner () {
        $(this.spinnerContainer).addClass("hide");
    }

    showNoResultsMsg () {
        $(this.noResultsContainer).removeClass("hide");
    }

    hideNoResultsMsg () {
        $(this.noResultsContainer).addClass("hide");
    }

    showLoadMore () {
        $(this.loadMoreContainer).removeClass("hide");
    }

    hideLoadMore () {
        $(this.loadMoreContainer).addClass("hide");
    }

    showClearFilters () {
        $(this.clearFiltersContainer).removeClass("hide");
    }

    hideClearFilters () {
        $(this.clearFiltersContainer).addClass("hide");
    }

    resetResultsContainer () {
        this.hideSpinner();
        this.hideNoResultsMsg();
        this.hideLoadMore();

        if (this.state.filterType !== FILTER_TYPES.LOAD_MORE) {
            $(this.searchResults).empty();
        }
    }

    render (searchResults, totalResults, filters, filterType) {
        this.hideSpinner();

        // Render results or no results message
        if (searchResults.length === 0) {
            this.showNoResultsMsg();
        } else {
            searchResults.forEach((r) => {
                $(this.searchResults).append($.parseHTML(r.resultMarkup, true));
            });
        }

        // Show load more button when there are more results to be shown
        if (((this.state.offset + 1) * this.MAX_RESULTS_PER_LOAD) < totalResults) {
            this.showLoadMore();
        }

        // Show clear filters button when there are user modified filters to be cleared
        if (this.state.filterType === FILTER_TYPES.DEFAULT) {
            this.hideClearFilters();
        } else {
            let showClear = false;
            for (let filterId in this.state.filters) {
                if (this.state.filters[filterId].length !== 0 && this.state.filters[filterId].join(",") !== this.ALL_FILTERS[filterId].join(",")) {
                    showClear = true;
                }
            }

            if (showClear) {
                this.showClearFilters();
            } else {
                this.hideClearFilters();
            }
        }
    }
}
window.FilterSearchbar = FilterSearchbar;   // Expose to be accessible globally