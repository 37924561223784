"use strict";

/**
 * @alias module:util/EventUtils.callIfOutsideOfContainer
 * @desc Function which will execute the callback only if the passed event is outside of the specified container.
 *     This is useful when we want to hide a container when the user clicks outside of it (such a popover).
 *
 * @param {object} container
 *     The DOM container node
 * @param {function} callback
 *     The callback to execute
 * @param {event} e
 *     The event to examine
 * @returns {boolean}
 *     Whether or not the event was outside of the container.  True means the event was outside
 */
exports.callIfOutsideOfContainer = function (container, callback, e) {
    var node = e.target;

    while (node.parentNode) {
        if (node === container) {
            return false;
        }
        //if we've bubbled all the way up to the top of the dom and still havent matched the container then
        //click was outside
        if (node === document.body) {
            callback(e);
            return true;
        }
        node = node.parentNode;
    }

    //if the item that was clicked is detached from the dom, then it's unknown if it's was outside, so do nothing
    return false;
};