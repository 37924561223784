import "core-js/stable";
import "regenerator-runtime/runtime";

import "./creators/searchbar.js";
import "./creators/sectionNav.js";
import "./creators/tabs.js";
import "./creators/background.js";
import "./creators/testimonialCarousel.js";
import "./creators/logoCarousel.js";
import "./modal.js";

import "./classes/Searchbar.js";
import "./classes/SearchResults.js";
import "./classes/BlogSearchbar.js";
import "./classes/FilterSearchbar.js";
import "./classes/Table.js";
import "./classes/TableMultiLevel.js";
import "./classes/InteractiveMap.js";
import "./classes/DoceboCourseDetails.js";
import "./classes/GroupedSearchbar.js";
import "./classes/ListSection.js";
import "./classes/SideNav.js";
import "./classes/PicForms.js";
import "./classes/DynamicRollover.js";
import "./classes/Card.js";

import "./picturefill.js";
import "./images.js";
import "./p14eTrial.js";

//const scrollAnimations init
window.toggleClass = function(className, target) {
    $(target).toggleClass(className);
};