import _ from "underscore";

const tabsContainers = document.getElementsByClassName("tabs__container");

class TabsContainer {
    constructor(container) {
        this.container = container;
        this._setItemOnclick = this._setItemOnclick.bind(this);
        this._getOffset = this._getOffset.bind(this);
        this._setContainerMargin = this._setContainerMargin.bind(this);
        this._updateDOM = this._updateDOM.bind(this);
        this._setCrossFadeContentHeight = this._setCrossFadeContentHeight.bind(this);

        this.activeIndex = 0;

        // Checking for the class name on the page to determine whether edit mode or publish mode.
        if (this.container.getElementsByClassName("tabs__list").length > 0) {
	        this.tabsContainer = this.container.getElementsByClassName(
	            "tabs__list"
	        )[0];
	        //this.lists = this.container.getElementsByTagName("ul");
	        this.tabsList = this.container.getElementsByClassName("tabs__list-ul")[
	            0
	        ];
	        this.tabs = this._getDirectList(this.tabsList);
	        this.panesList = this.container.getElementsByClassName("tabs__content")[
	            0
	        ];
	        this.panes = this._getDirectList(this.panesList);

	        this.isMobile = !(window.getComputedStyle(this.tabsList).display ===
	            "flex");

	        this.isMobile && this._setContainerMargin();
	        this._updateDOM(this.activeIndex);
            this._setCrossFadeContentHeight();
            window.addEventListener("resize", this._setCrossFadeContentHeight);
	        this._setItemOnclick();
        }
    }
    _getDirectList(ul) {
        return _.filter(ul.childNodes, child => {
            return(child.nodeType === 1 && child.tagName === "LI");
        });
    }
    _setContainerMargin() {
        let marginLeft = this._getOffset(this.tabs[0], this.tabsList);
        let marginRight = this._getOffset(
            this.tabs[this.tabs.length - 1],
            this.tabsList,
            "right"
        );

        this.tabsList.style.paddingLeft = -marginLeft + "px";
        this.tabsList.style.paddingRight = marginRight + "px";
    }

    _getOffset(tab, container) {
        let offset = (window.innerWidth - container.offsetWidth) / 2;
        return tab.offsetLeft -
            container.offsetWidth / 2 +
            tab.offsetWidth / 2 -
            offset;
    }

    _updateDOM(nextActive) {
        //remove current active
        $(this.tabs[this.activeIndex]).removeClass("active");
        $(this.panes[this.activeIndex]).removeClass("active");

        //set next active
        this.activeIndex = nextActive;
        let activeTab = this.tabs[this.activeIndex];
        let activePane = this.panes[this.activeIndex];
        $(activeTab).addClass("active");
        $(activePane).addClass("active");

        //scroll on mobile
        this.isMobile &&
            $(this.tabsContainer).animate(
                {scrollLeft: this._getOffset(activeTab, this.tabsContainer)},
                300
            );
    }

    _setCrossFadeContentHeight() {
        // Set height on tabs__content--crossFade element to tallest child so there is no jump on fading btw/ panes
        if (this.panesList.classList.contains("tabs__content--crossFade")) {
            let maxHeight = 0;
            for (let i=0; i < this.panes.length; i++) {
                let totalHeight = 0;
                $(this.panes[i]).children().each((i, el) => {
                    totalHeight = totalHeight + $(el).outerHeight(true);
                });
                maxHeight = Math.max(maxHeight, totalHeight);
            }
            let tabsContentPadding = 0;
            tabsContentPadding += parseInt($(this.panesList).css("padding-top").replace("px", ""));
            tabsContentPadding += parseInt($(this.panesList).css("padding-bottom").replace("px", ""));
            $(this.panesList).css("height", (maxHeight + tabsContentPadding) + "px");
        }
    }

    _setItemOnclick() {
        _.each(this.tabs, (tab, index) => {
            tab.onclick = e => {
                this._updateDOM(index);
            };
        });
    }
}

if (tabsContainers[0] !== " ") {
    _.each(tabsContainers, container => {
        new TabsContainer(container);
    });
}
