"use strict";

export default class DynamicRollover {
    constructor (elementIDs) {
        // Bind callbacks to 'this' so callbacks have correct 'this' context
        // Done here in constructor so it is only bound ONCE, rather than per call
        this._handleTriggerHover = this._handleTriggerHover.bind(this);

        // Bind necessary DOM elements to class
        this.node = document.getElementById(elementIDs.container);
        this.triggers = this.node.querySelectorAll(".dynamic-rollover_trigger");
        this.targetContainer = this.node.querySelector(".dynamic-rollover_target__container");

        // Set initial state
        let initialState = {
            activeId: null
        };
        this.state = initialState;

        // Initialize & bind callbacks
        this.initTriggers();
        this._initDefaultActive();
        this._setCrossFadeContentHeight();
        window.addEventListener("resize", this._setCrossFadeContentHeight.bind(this));
    }

    initTriggers () {
        let triggers = this.node.getElementsByClassName("dynamic-rollover_trigger");
        for (let i=0; i<triggers.length; i++) {
            $(triggers[i]).hover(this._handleTriggerHover.bind(this, triggers[i]));
        }
    }

    _initDefaultActive () {
        // Use the First Trigger as the default
        let firstTrigger = this.triggers[0];
        this.state.activeId = firstTrigger.dataset.triggerId;
        this._toggleTrigger(firstTrigger);

        // Also set its corresponding Target to active state
        let target = this._getTargetNode(this.state.activeId);
        this._toggleTarget(target);
    }

    _setCrossFadeContentHeight () {
        // Set height on dynamic-rollover_target__container element to tallest child for positioning its absolute children
        // and so there is no jump on fading btw/ targets.
        let maxHeight = 0;
        for (let i=0; i < this.targetContainer.children.length; i++) {
            let totalHeight = 0;
            $(this.targetContainer.children[i]).children().each((i, el) => {
                totalHeight = totalHeight + $(el).outerHeight(true);
            });
            maxHeight = Math.max(maxHeight, totalHeight);
        }
        $(this.targetContainer).css("height", maxHeight + "px");
    }

    _handleTriggerHover (trigger, e) {
        e.preventDefault();

        if (this.state.activeId) {
            // Unset last active trigger
            let lastActiveTrigger = this._getTriggerNode(this.state.activeId);
            this._toggleTrigger(lastActiveTrigger);

            // Unset last active target
            let lastActiveTarget = this._getTargetNode(this.state.activeId);
            this._toggleTarget(lastActiveTarget);
        }

        this.state.activeId = trigger.dataset.triggerId;

        // Set new active trigger
        let activeTrigger = this._getTriggerNode(this.state.activeId);
        this._toggleTrigger(activeTrigger);

        // Set new active target
        let activeTarget = this._getTargetNode(this.state.activeId);
        this._toggleTarget(activeTarget);
    }

    _getTriggerNode (triggerId) {
        return this.node.querySelector("[data-trigger-id='" + triggerId + "']");
    }

    _toggleTrigger (trigger) {
        $(trigger.firstElementChild).toggleClass(trigger.dataset.triggerHoverClass);
    }

    _getTargetNode (targetId) {
        return this.targetContainer.querySelector("[data-target-id='" + targetId + "']");
    }

    _toggleTarget (target) {
        $(target).toggleClass("dynamic-rollover__target--active");
    }
}
window.DynamicRollover = DynamicRollover;   // Expose to be accessible globally