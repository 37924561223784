module.exports={
    "props": {
        "BUTTON-SMALL": {
            "value": "'button--small'",
            "type": "size",
            "category": "button"
        },
        "BUTTON-LARGE": {
            "value": "'button--large'",
            "type": "size",
            "category": "button"
        },
        "BUTTON-PRIMARY": {
            "value": "'button--primary'",
            "type": "style",
            "category": "button"
        },
        "BUTTON-SECONDARY": {
            "value": "'button--secondary'",
            "type": "style",
            "category": "button"
        },
        "BUTTON-WHITE": {
            "value": "'button--white'",
            "type": "style",
            "category": "button"
        },
        "BUTTON-RED": {
            "value": "'button--red'",
            "type": "style",
            "category": "button"
        },
        "BUTTON-HOLLOW-BLUE": {
            "value": "'button--hollow-blue'",
            "type": "style",
            "category": "button"
        },
        "BUTTON-HOLLOW-BERMUDA-GRAY": {
            "value": "'button--hollow-bermuda-gray'",
            "type": "style",
            "category": "button"
        },
        "BUTTON-HOLLOW-LIGHT": {
            "value": "'button--hollow-light'",
            "type": "style",
            "category": "button"
        },
        "BUTTON-HOLLOW-DARK": {
            "value": "'button--hollow-dark'",
            "type": "style",
            "category": "button"
        },
        "BUTTON-HOLLOW-RED": {
            "value": "'button--hollow-red'",
            "type": "style",
            "category": "button"
        },
        "BUTTON-LOWERCASE": {
            "value": "'button--lowercase'",
            "type": "size",
            "category": "button"
        },
        "BUTTON-DEMO": {
            "value": "'button--demo'",
            "type": "style",
            "category": "button"
        },
        "BUTTON-CONNECT": {
            "value": "'button--connect'",
            "type": "style",
            "category": "button"
        },
    }
}
