"use strict";
import jump from "jump.js";
export default class TableMultiLevel {
    constructor (elementIDs, rows, cols) {
        // Bind callbacks to 'this' so callbacks have correct 'this' context
        // Done here in constructor so it is only bound ONCE, rather than per call
        this._handleRowToggle = this._handleRowToggle.bind(this);
        this._handleExpandAll = this._handleExpandAll.bind(this);
        this._handleCollapseAll = this._handleCollapseAll.bind(this);
        this._handleExpandCollapseHoverTop = this._handleExpandCollapseHoverTop.bind(this);
        this._handleExpandCollapseHoverBottom = this._handleExpandCollapseHoverBottom.bind(this);
        this._handleExpandCollapseBlurTop = this._handleExpandCollapseBlurTop.bind(this);
        this._handleExpandCollapseBlurBottom = this._handleExpandCollapseBlurBottom.bind(this);

        // Bind necessary DOM elements to class
        this.node = document.getElementById(elementIDs.container);

        // Constants
        this.NUM_ROWS = rows;
        this.NUM_COLS = cols;
        this.uuid = elementIDs.uuid;
        this.expandText = elementIDs.expandText;
        this.collapseText = elementIDs.collapseText;


        // State variables
        this.activeRows = [];


        // Bind callbacks to elements
        for (var i=1; i <= this.NUM_ROWS; i++) {
            this.initRow("row-" + i);
        }

        const vw = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

        //Initalize ExpandAll Buttons on top
        let expandAllTop = document.getElementById(elementIDs.expandAllTop);
        expandAllTop.onclick = this._handleExpandAll;

        //Initalize ExpandAll Buttons on bottom
        let expandAllBottom = document.getElementById(elementIDs.expandAllBottom);
        expandAllBottom.onclick = this._handleExpandAll;

        //Initialize Collapse All Button on Top
        let collapseAllTop = document.getElementById(elementIDs.collapseAllTop);
        collapseAllTop.onclick = this._handleCollapseAll;

        //Initialize Collapse All Button on Bottom
        let collapseAllBottom = document.getElementById(elementIDs.collapseAllBottom);
        collapseAllBottom.onclick = this._handleCollapseAll;

        //Initialize Expand/Collapse controls on top for non-mobile devices
        let expandCollapseControlsTop = document.getElementById(elementIDs.expandCollapseControlsTop);
        if (vw > 992) {
            expandCollapseControlsTop.onmouseover = this._handleExpandCollapseHoverTop;
            expandCollapseControlsTop.onmouseout = this._handleExpandCollapseBlurTop;
        }

        //Initialize Expand/Collapse controls on bottom
        let expandCollapseControlsBottom = document.getElementById(elementIDs.expandCollapseControlsBottom);
        if (vw > 992) {
            expandCollapseControlsBottom.onmouseover = this._handleExpandCollapseHoverBottom;
            expandCollapseControlsBottom.onmouseout = this._handleExpandCollapseBlurBottom;
        }
    }

    initRow (rowId) {
        let row = this.node.querySelector("[data-row-id='" + rowId + "']");
        if (!row) {
            return;
        }
        if (row.dataset.rowExpandable == "true") {
            row.children[0].onclick = this._handleRowToggle;
            row.children[0].name = this.uuid + "__expandable";
        }

        let childrenContainer = row.querySelector(".table__row__children");
        if (childrenContainer) {
            for (var i=0; i < childrenContainer.children.length; i++) {
                let rowId = childrenContainer.children[i].dataset.rowId;
                this.initRow(rowId);
            }
        }
    }

    _handleRowToggle (e) {
        e.preventDefault();
        let row = e.currentTarget.parentElement;

        // User initiated expand/collapse
        $(row).toggleClass("table__row--expanded");

        let menuState = this._handleExpandCollapseState(this.uuid);
        this._handleExpandCollapse(menuState, this.uuid);

        // Dynamic expand/collapse accordion - others in same level close when new one opened
        // let rowLevel = row.dataset.rowLevel;
        // if (this.activeRows[rowLevel] && this.activeRows[rowLevel] != row) {
        //     $(this.activeRows[rowLevel]).removeClass("table__row--expanded");
        // }
        // $(row).toggleClass("table__row--expanded");
        // this.activeRows[rowLevel] = row
    }

    //Expand all menu items
    _handleExpandAll (e) {
        e.preventDefault();
        let table = document.getElementById(this.uuid + "__tableContainer");

        let rows = table.querySelectorAll(".table__row")

        if(rows) {
            for (var i=0; i < rows.length; i++) {
                if(!rows[i].classList.contains("table__row--expanded") && rows[i].dataset.rowExpandable == "true") {
                    rows[i].classList.add("table__row--expanded");
                }
            }
        }

        let menuState = this._handleExpandCollapseState(this.uuid);
        this._handleExpandCollapse(menuState, this.uuid);
    }

    //Collapse all menu items
    _handleCollapseAll (e) {
        e.preventDefault();

        //Cubic transition for scroll on collapse
        const easeInOutCubic = function(t, b, c, d) {
            if ((t /= d / 2) < 1) return c / 2 * t * t * t + b;
            return c / 2 * ((t -= 2) * t * t + 2) + b;
        };

        let table = document.getElementById(this.uuid + "__tableContainer");
        let rows = table.querySelectorAll(".table__row");

        if(rows) {
            for (var i=0; i < rows.length; i++) {
                if(rows[i].dataset.rowExpandable == "true") {
                    rows[i].classList.remove("table__row--expanded");
                }
            }
        }

        let menuState = this._handleExpandCollapseState(this.uuid);
        this._handleExpandCollapse(menuState, this.uuid);

        //Smooth scroll on collapse
        jump(table, {
            offset: -500,
            duration: 1000,
            easing: easeInOutCubic
        });
    }

    //Modify container class for proper expand/collapse state
    _handleExpandCollapse(menuState, uuid) {

        let expandCollapseContainer = document.getElementsByName(uuid + "__expandCollapse--controls");

        for (var i=0; i < expandCollapseContainer.length; i++) {
            if (menuState === "collapse") {
                expandCollapseContainer[i].className = "expand__collapse--controls expand";
            } else if (menuState === "expand") {
                expandCollapseContainer[i].className = "expand__collapse--controls collapse";
            }
                //else {
//              expandCollapseContainer[i].className = "expand__collapse--controls combo";
//          }
        }
    }

    //On Hover event to display Expand All button
    _handleExpandCollapseHoverTop (e) {
        if (e.currentTarget.classList.contains("expand")) {
            document.getElementById(this.uuid + "__expand--allTop").innerHTML = "[ + Expand All ] ";
        } else if (e.currentTarget.classList.contains("collapse")) {
            document.getElementById(this.uuid + "__collapse--allTop").innerHTML = "[ - Collapse All ]";
        } else {
            document.getElementById(this.uuid + "__expand--allTop").innerHTML = "[ + Expand All ]";
            document.getElementById(this.uuid + "__collapse--allTop").innerHTML = " [ - Collapse All ]";
        }

    }

    //On Blur event to hide Expand All button
    _handleExpandCollapseBlurTop (e) {
        document.getElementById(this.uuid + "__expand--allTop").innerHTML = "[ + ]";
        document.getElementById(this.uuid + "__collapse--allTop").innerHTML = "[ - ]";
    }

    //On Hover event to display Collapse All button
    _handleExpandCollapseHoverBottom (e) {
        if (e.currentTarget.classList.contains("expand")) {
            this.expandText = this.expandText;
        } else if (e.currentTarget.classList.contains("collapse")) {
            this.collapseText = this.collapseText;
        }
    }

    //On Blur event to hide Collapse All button
    _handleExpandCollapseBlurBottom (e) {
        this.expandText = this.expandText;
        this.collapseText = this.collapseText;
    }

    //Method to determine whether state of menu is collapsed or expanded
    _handleExpandCollapseState (uuid) {
        let table = document.getElementById(uuid + "__tableContainer");
        let rows = table.getElementsByClassName("table__row");
        let check = 0;
        let length = 0;

        //Calculate whether all rows are expanded or collapsed
        if(rows) {
            for (var i=0; i < rows.length; i++) {
                //Count only expandable rows
                if (rows[i].dataset.rowExpandable == "true") {
                    length++;
                }
                //Count expanded rows
                if(rows[i].classList.contains("table__row--expanded") && rows[i].dataset.rowExpandable == "true") {
                    check++;
                }
            }
        }

        //Return state for expand collapse controls
        if (check === 0) {
            return "collapse";
        } else if (check === length) {
            return "expand";
        }
//        else {
//          return "combo";
//        }

    }
}
window.TableMultiLevel = TableMultiLevel;   // Expose to be accessible globally
