import _ from "underscore";


function getResponsiveBg(source) {
    return (pingCloudinaryEnabled && pingCloudinaryURL != null) ? pingCloudinaryURL + source : source;
}

//set img
[].forEach.call(document.querySelectorAll("[data-src]"), imgNode => {
    let source = imgNode.getAttribute("data-src");
    let request = getResponsiveBg(source);
    imgNode.src = request;
    imgNode.removeAttribute("data-src");

});

function isElementInViwport (node, offset) {
    let rect = node.getBoundingClientRect();
    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
}
function isElementInOffset (node, offset) {
    let top = $(node).offset().top;
    let bottom = top + $(node).height();
    let windowTop = $(window).scrollTop();
    let windowBottom = windowTop + $(window).height();
    if (top - offset <= windowBottom && bottom + offset >= windowTop) {
        return true;
    }
}
function isInView (node, offset) {
    return isElementInOffset(node, offset) || isElementInViwport(node);
}
//set bg images
[].forEach.call(document.querySelectorAll("[data-bg-src]"), bgNode => {
    let source = bgNode.getAttribute("data-bg-src");
    $(bgNode).hide();
    let request = getResponsiveBg(source);
    let bgImg = new Image();

    bgImg.onload = () => {
        $(bgNode).css("background-image", `url(${bgImg.src})`) ;
        bgNode.removeAttribute("data-bg-src");
        if (shouldShow()) {
            show();
        }
        else {
            window.addEventListener("scroll", _.throttle(showScroll, 100));
        }
    };
    //start loading if 500px from view
    let load = () => {
        if (isInView(bgNode.parentNode, 500)) {
            bgImg.src = request;
            window.removeEventListener("scroll", _.throttle(load, 100));
        }
    };
    //should start fade in once 100px in view to keep consistency
    let shouldShow = () => {
        return isInView(bgNode.parentNode, -100);
    }
    let showScroll = () => {
        if (shouldShow()) {
            show();
            window.removeEventListener("scroll",  _.throttle(showScroll, 100));
        }
    }
    let show = () => {
        $(bgNode).fadeIn();
    }
    load();
    window.addEventListener("scroll", _.throttle(load, 100));


});
