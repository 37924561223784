"use strict";

import {SectionNav} from "../classes/SectionNav";
import {navClassNames} from "../classes/Nav";

if (document.getElementById("sectionNav")) {
    const navParams = () => {
        let node = document.getElementById("sectionNav");
        let customButtons = node.dataset.customButtons;

        let mainNav = null;
        let standalonePlaceholderContainers = document.getElementsByClassName("ping-pic__standalone-container");
        for (let i=0; i < standalonePlaceholderContainers.length; i++) {
            let item = standalonePlaceholderContainers[i];
            if (item.dataset.component === "top-nav" || item.dataset.component === "p14c-top-nav") {
                mainNav = item;
                break;
            }
        }

        let toggleButtons = () => {
            if (customButtons) {
                $(node).addClass("section-nav__buttons--visible");
            } else {
                let topNav = mainNav.querySelector("#topNav");
                if (topNav && $(topNav).hasClass(navClassNames.MINIMIZED) && !$(node).hasClass("p14c")) {
                    $(node).addClass("section-nav__buttons--visible");
                } else {
                    $(node).removeClass("section-nav__buttons--visible");
                }
            }
        };
        let options = {
            mainNav: mainNav,
            onScroll: toggleButtons,
            onLoad: toggleButtons,
            customButtons: customButtons
        };
        return {
            node: node,
            options: options
        };
    };
    const nav2 = navParams();
    new SectionNav(nav2.node, nav2.options);
}
