"use strict";

export default class Card {
    constructor (elementIDs) {
        // Bind necessary DOM elements to class
        this.node = document.getElementById(elementIDs.container);

        // Constants
        this.BLEED_OUT_MIN_WIDTH = 1200;    // Bleedout is restricted to screen widths btw/ 1200px - 1400px
        this.BLEED_OUT_MAX_WIDTH = 1400;
        this.CARD_INITIAL_WIDTH = $(this.node).innerWidth();    // Hold initial card width & padding values for bleedout resize snap
        this.CARD_INITIAL_PADDING_RIGHT = $(this.node).css("padding-right");

        // Initialize & bind callbacks
        this._setBleedoutCardWidth();
        window.addEventListener("resize", this._setBleedoutCardWidth.bind(this));
    }

    _setBleedoutCardWidth () {
        // Set width of cards that should bleedout past a container and go completely across the screen
        if (this.node.dataset.bleedOut && ($(window).width() >= this.BLEED_OUT_MIN_WIDTH && $(window).width() <= this.BLEED_OUT_MAX_WIDTH)) {
            $(this.node).css("width", "100vw"); // 100vw to ensure it'll be wide enough to stretch across the screen
            $(this.node).css("padding-right", "calc(100vw - " + this.CARD_INITIAL_WIDTH + "px)");
        } else {
            // Potentially need to reset on resize
            $(this.node).css("width", "initial");
            $(this.node).css("padding-right", this.CARD_INITIAL_PADDING_RIGHT);
        }
    }
}
window.Card = Card;   // Expose to be accessible globally