"use strict";

import {Nav} from "./Nav.js";
import _ from "underscore";
import jump from "jump.js";

//polyfill for requestAnimationFrame on jump.js
(function() {
    var lastTime = 0;
    var vendors = ["webkit", "moz"];
    for (var x = 0; x < vendors.length && !window.requestAnimationFrame; ++x) {
        window.requestAnimationFrame = window[
            vendors[x] + "RequestAnimationFrame"
        ];
        window.cancelAnimationFrame = window[
            vendors[x] + "CancelAnimationFrame"
        ] || window[vendors[x] + "CancelRequestAnimationFrame"];
    }

    if (!window.requestAnimationFrame)
        window.requestAnimationFrame = function(callback) {
            var currTime = new Date().getTime();
            var timeToCall = Math.max(0, 16 - (currTime - lastTime));
            var id = window.setTimeout(
                function() {
                    callback(currTime + timeToCall);
                },
                timeToCall
            );
            lastTime = currTime + timeToCall;
            return id;
        };

    if (!window.cancelAnimationFrame)
        window.cancelAnimationFrame = function(id) {
            clearTimeout(id);
        };
})();

const easeInOutCubic = function(t, b, c, d) {
    if ((t /= d / 2) < 1) return c / 2 * t * t * t + b;
    return c / 2 * ((t -= 2) * t * t + 2) + b;
};

class SectionNav extends Nav {
    constructor(nav, opts = {}) {
        super(nav, opts);
        this._setOffset = this._setOffset.bind(this);
        this._insertLinks = this._insertLinks.bind(this);
        this._getLinks = this._getLinks.bind(this);
        this._setLinkOnClick = this._setLinkOnClick.bind(this);
        this._getHighlightLink = this._getHighlightLink.bind(this);
        this._highlightLink = this._highlightLink.bind(this);
        this._getOffset = this._getOffset.bind(this);
        this._getCTAs = this._getCTAs.bind(this);
        this.onScroll = this.onScroll.bind(this);

        this.mainNav = opts.mainNav || null;

        this.links = this._getLinks();
        this._insertLinks(this.links);

        if (this.mainNav) {
            this._setOffset();
            this._getCTAs(opts);
        }

        this.state = _.extend(
            {
                jumping: false,
                highlightedLink: null,
                buffer: opts.buffer || 100
            },
            this.state
        );
        this._highlightLink(this._getHighlightLink());
    }

    _setOffset() {
        //set offsets to keep nav on screen
        let offset = this.nav.offsetHeight + 10;
        this.navRect.top = this.navRect.top - offset;
        this.navRect.bottom = this.navRect.bottom - offset;
    }

    _getCTAs(opts) {
        // do nothing if sectionNav has its own custom buttons
        if (opts.customButtons) {
            return;
        }
        //get navbar buttons from main menu
        if (this.mainNav) {
            // Have to wait until page load for Standalone header topNav to be completely loaded
            window.addEventListener("resLoad", function (e){
            	let ul = this.nav.getElementsByTagName("ul")[0];
                let cta = this.mainNav
                    .getElementsByClassName("navbar__buttons")[0]
                    .cloneNode(true);
                ul.parentNode.insertBefore(cta, ul.nextSibling);
            }.bind(this));
        }
    }

    _insertLinks(links) {
        let ul = this.nav.getElementsByTagName("ul")[0];
        _.each(links, link => {
            let li = document.createElement("li");
            li.appendChild(link.node);
            ul.appendChild(li);
        });
    }

    _getLinks() {
        //let links = {};
        //let targets =
    	
    	let path = window.location.pathname;
    	let page = path.split("/").pop().replace(".html", "");
    	
        return _.map(
            document.querySelectorAll("[data-scroll-target]"),
            target => {
                let node = document.createElement("a");
                node.title = target.getAttribute("data-scroll-target");
                node.id = "section-nav-" + page + "-" + target.getAttribute("data-scroll-target");
                node.innerHTML = target.getAttribute("data-scroll-target");
                this._setLinkOnClick(node, target);
                return {
                    node: node,
                    target: target
                };
            }
        );
    }

    _setLinkOnClick(link, target) {
        link.onclick = () => {
            if (this.state.jumping) {
                return;
            }
            let targetTop = target.getBoundingClientRect().top;
            let offset = targetTop < 0
                ? this.nav.offsetHeight * 2
                : this.nav.offsetHeight;
            this.state.jumping = true;
            this._highlightLink(link);
            jump(target, {
                offset: -offset,
                duration: distance => Math.abs(distance),
                callback: () => this.state.jumping = false,
                easing: easeInOutCubic
            });
        };
    }

    _getHighlightLink() {
        if (this.nav.getBoundingClientRect().top > this.mainNav.offsetHeight) {
            return null;
        }
        let offset = this.nav.getBoundingClientRect().bottom;
        let top = $(window).scrollTop() + offset + this.state.buffer;
        let highlight = null;
        this.links.forEach(link => {
            let targetRect = this._getOffset(link.target);
            if (top >= targetRect.top && top < targetRect.bottom) {
                highlight = link.node;
            }
        });
        return highlight;
    }

    _highlightLink(link) {
        $(this.state.highlightedLink).removeClass("active");
        $(link).addClass("active");
        this.state.highlightedLink = link;
    }

    _getOffset(el) {
        let rect = el.getBoundingClientRect();
        let top = rect.top +
            window.pageYOffset -
            document.documentElement.clientTop;
        return {
            top: top,
            bottom: top + rect.height
        };
    }

    onScroll() {
        if (getComputedStyle(this.nav).display === "none") {
            return;
        }
        if (this.state.jumping) {
            super.onScroll();
            return;
        }
        //Do nothing if pixelOffset isn't reached
        if (
            Math.abs($(window).scrollTop() - this.state.lastScroll) <
            this.pixelOffset
        ) {
            return;
        }
        let link = this._getHighlightLink();
        if (this.state.highlightedLink !== link) {
            this._highlightLink(link);
        }
        super.onScroll();
    }
}

export {SectionNav};
