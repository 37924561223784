export default function ProductReleaseCard (props) {
    const card = document.createElement("div");
    card.className = "card--productrelease-card";
    card.onclick = () => window.open(props.link, "_top");
    card.innerHTML = `
        <div class="card__body">
        	<div class="productRelease-date">${props.postDate}</div>
            <h6 class="productRelease-title" title="${props.heading}">${props.heading}</h6>
            <div class="card--productrelease-card__body">${props.text}</div>
        </div>
        <a class="button button--small button--hollow-blue" href="${props.link}">${props.buttonLabel}</a>
    	`;
	return card;
}