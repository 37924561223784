

"use strict";

const P14ESignupErrors = {
    INVALID_FIRST_NAME: "INVALID_FIRST_NAME",
    INVALID_LAST_NAME: "INVALID_LAST_NAME",
    INVALID_EMAIL: "INVALID_EMAIL",
    INVALID_PHONE: "INVALID_PHONE",
    INVALID_COMPANY: "INVALID_COMPANY",
    INVALID_STATE: "INVALID_STATE",
    INVALID_CAPTCHA: "INVALID_CAPTCHA",
    INVALID_TERMS_OF_SERVICE: "INVALID_TERMS_OF_SERVICE",
    MISSING_REQUIRED_FIELDS: "MISSING_REQUIRED_FIELDS",
    INVALID_PASSWORD_REPEATED_CHARS: "INVALID_PASSWORD_REPEATED_CHARS",
    INVALID_PASSWORD_NON_UNIQUE_CHARS: "INVALID_PASSWORD_NON_UNIQUE_CHARS",
    INVALID_PASSWORD_LENGTH: "INVALID_PASSWORD_LENGTH",
    INVALID_PASSWORD_NO_DIGITS: "INVALID_PASSWORD_NO_DIGITS",
    INVALID_PASSWORD_NO_LOWERCASE: "INVALID_PASSWORD_NO_LOWERCASE",
    INVALID_PASSWORD_NO_UPPER_CASE: "INVALID_PASSWORD_NO_UPPER_CASE",
    INVALID_PASSWORD_NO_SPECIAL_CHARS: "INVALID_PASSWORD_NO_SPECIAL_CHARS"
}

function P14ETrialSignup (elementIDs, isAccountSignup, showPasswordLabel, hidePasswordLabel) {
    // Bind callbacks to 'this' so callbacks have correct 'this' context
    // Done here in constructor so it is only bound ONCE, rather than per call
    this._handleSubmit = this._handleSubmit.bind(this);
    this._handlePasswordToggle = this._handlePasswordToggle.bind(this);
    this._handleExistingAccountAccept = this._handleExistingAccountAccept.bind(this);
    this._handleCountryChange = this._handleCountryChange.bind(this);

    // Bind necessary DOM elements to class
    for (let key in elementIDs) {
        this[key] = document.getElementById(elementIDs[key]);
    }

    // Constants
    this.SHOW_PASSWORD_LABEL = showPasswordLabel;
    this.HIDE_PASSWORD_LABEL = hidePasswordLabel;

    // State variables
    this.isAccountSignup = isAccountSignup === "true";

    // Initialize account signup or create
    if (this.isAccountSignup) {
        this._initSignup();
    } else {
        this._initCreate();
    }
}
P14ETrialSignup.prototype._initSignup = function () {
    this.form.onsubmit = this._handleSubmit;

    // TODO - uncomment for open enrollment
    // this.formPasswordToggle.onclick = this._handlePasswordToggle;

    //this.existingAccountAcceptButton.onclick = this._handleExistingAccountAccept;
    this.formCountrySelect.onchange = this._handleCountryChange;
}
P14ETrialSignup.prototype._handleSubmit = function (e) {
    e.preventDefault();
    this._doSignup();
}
P14ETrialSignup.prototype._handlePasswordToggle = function (e) {
    e.preventDefault();

    let passwordType = this.form.password.getAttribute("type");
    if (passwordType === "password") {
        this.form.password.setAttribute("type", "text");
        this.formPasswordToggle.textContent = this.HIDE_PASSWORD_LABEL;
    } else {
        this.form.password.setAttribute("type", "password");
        this.formPasswordToggle.textContent = this.SHOW_PASSWORD_LABEL;
    }
}
P14ETrialSignup.prototype._handleExistingAccountAccept = function (e) {
    e.preventDefault();

    if (e.currentTarget.getAttribute("href")) {
        window.location.href = e.currentTarget.href;
    }

    this._hideExistingAccount();
}
P14ETrialSignup.prototype._handleCountryChange = function (e) {
    let selectedCountry = this.formCountrySelect.value;

    if (selectedCountry) {
        if (selectedCountry == 'Germany' || selectedCountry == 'Switzerland') {
            this._showElement("formMarketingOptIn");
            this._hideElement("formMarketingOptOut");
        } else if (selectedCountry == 'Canada' || selectedCountry == 'United Kingdom' ||
                selectedCountry == 'Austria' || selectedCountry == 'Hungary' || selectedCountry == 'Ireland' ||
                selectedCountry == 'Luxembourg' || selectedCountry == 'Portugal' || selectedCountry == 'Sweden' ||
                selectedCountry == 'Belgium' || selectedCountry == 'Bulgaria' || selectedCountry == 'Croatia' ||
                selectedCountry == 'Cyprus' || selectedCountry == 'Czech Republic' || selectedCountry == 'Denmark' ||
                selectedCountry == 'Estonia' || selectedCountry == 'Finland' || selectedCountry == 'France' ||
                selectedCountry == 'Greece' || selectedCountry == 'Italy' || selectedCountry == 'Latvia' ||
                selectedCountry == 'Lithuania' || selectedCountry == 'Malta' || selectedCountry == 'Netherlands' ||
                selectedCountry == 'Poland' || selectedCountry == 'Romania' || selectedCountry == 'Slovak Republic' ||
                selectedCountry == 'Slovenia' || selectedCountry == 'Spain') {
            this._showElement("formMarketingOptOut");
            this._hideElement("formMarketingOptIn");
        } else {
            this._hideElement("formMarketingOptIn");
            this._hideElement("formMarketingOptOut");
        }
        if (selectedCountry == 'United States') {
            this._showElement("formStateSelectId");
        } else {
        	this._hideElement("formStateSelectId");
        }
    }
}
P14ETrialSignup.prototype._initCreate = function () {
    this._getCSRFToken();
    this.existingAccountAcceptButton.onclick = this._handleExistingAccountAccept;
}
P14ETrialSignup.prototype._doSignup = function () {
    this._getCSRFToken();
}
P14ETrialSignup.prototype._getCSRFToken = function () {

        if (this.isAccountSignup) {
            let query = this._buildSignUpQuery(
                this.form.firstName.value,
                this.form.lastName.value,
                this.form.email.value,
                this.form.phone.value,
                this.form.company.value,
                this.form.interestIn.value,
                this.form.annualRevenue.value,
                this.form.region.value,
                this.form.country.value,
                this.form.state.value,
                this.form.marketingOptIn.checked,
                this.form.marketingOptOut.checked,
                this.form.termsAndServices.checked,
                this.form["g-recaptcha-response"].value,
                this.form.utmmedium.value,
                this.form.utmsource.value,
                this.form.utmterm.value,
                this.form.utmcampaign.value,
                this.form.utmcontent.value

            );
            this._signup(query);
        } else {
            let code = getUrlParameter("code");
            let query = this._buildVerifyCreateQuery(code);
            this._verifyAndCreate(query);
        }

    if (this.isAccountSignup) {
        this._showElement("formSpinner");
    }
}
P14ETrialSignup.prototype._signup = function (query) {
    let xhr = new XMLHttpRequest();
    xhr.onload = function () {
        if (xhr.status === 200) {
            try {
                let response = JSON.parse(xhr.responseText);

                switch(response.status) {
                    case 200:
                        this._hideElement("form");
                        let user = JSON.parse(response.value);
                        this._showVerification(user.firstName, user.email);
                        break;
                    case 400:
                    	grecaptcha.reset();
                        response.errors.forEach(function (error) {
                            this._processSignUpError(error);
                        }.bind(this));
                        break;
                    case 409:
                    	grecaptcha.reset();
                        this._showExistingAccount();
                        break;
                    case 406:
                    	grecaptcha.reset();
                    	this._showError("formEmailDomainError");
                        break;
                    default:
                    	grecaptcha.reset();
                        this._showError("formGeneralError");
                        break;
                }

                this._hideElement("formSpinner");
                this._enableSignUpSubmit();
            } catch (e) {
            	grecaptcha.reset();
                this._showError("formGeneralError");
            }
        } else {
            this._showError("formGeneralError");
            throw new Error("Bad response from server");
        }
    }.bind(this);
    xhr.onerror = function () {
        console.log("Error! Unable to sign up account.");
    }
    xhr.open("POST", "/bin/ping/p14eTrialSignup", true);
    xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
    //xhr.setRequestHeader("CSRF-Token", token);
    xhr.send(query);

    this._clearAllSignUpErrors();
    this._showElement("formSpinner");
    this._disableSignUpSubmit();
}
P14ETrialSignup.prototype._buildSignUpQuery = function (firstName, lastName, email, phone, company, interestIn, annualRevenue, region, country, state, marketingOptIn, marketingOptOut, termsAndService, recaptcha, utmmedium, utmsource, utmterm, utmcampaign, utmcontent ) {
    const params = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        phone: phone,
        company: company,
        interestIn: interestIn,
        annualRevenue: annualRevenue,
        region: region,
        country: country,
        state: state,
        marketingOptIn: marketingOptIn,
        marketingOptOut: marketingOptOut,
        termsAndService: termsAndService,
        recaptcha: recaptcha,
        utmmedium: utmmedium,
        utmsource: utmsource,
        utmterm: utmterm,
        utmcampaign: utmcampaign,
        utmcontent: utmcontent
    };

    const esc = encodeURIComponent;
    return Object.keys(params)
        .map(function (k) {
            return esc(k) + "=" + esc(params[k]);
        })
        .join("&");
}

P14ETrialSignup.prototype._buildVerifyCreateQuery = function (code) {
    const params = {
        code: code
    };

    const esc = encodeURIComponent;
    return Object.keys(params)
        .map(function (k) {
            return esc(k) + "=" + esc(params[k]);
        })
        .join("&");
}
P14ETrialSignup.prototype._processSignUpError = function (error) {
    switch(error) {
        case P14ESignupErrors.INVALID_FIRST_NAME:
            this._showError("formFirstNameError", true);
            break;
        case P14ESignupErrors.INVALID_LAST_NAME:
            this._showError("formLastNameError", true);
            break;
        case P14ESignupErrors.INVALID_EMAIL:
            this._showError("formEmailError", true);
            break;
        case P14ESignupErrors.INVALID_PHONE:
            this._showError("formPhoneError", true);
            break;
        case P14ESignupErrors.INVALID_COMPANY:
            this._showError("formCompanyError", true);
            break;
        case P14ESignupErrors.INVALID_STATE:
            this._showError("formStateError", true);
            break;
        case P14ESignupErrors.INVALID_CAPTCHA:
            this._showError("formCaptchaError", true);
            break;
        case P14ESignupErrors.INVALID_TERMS_OF_SERVICE:
            this._showError("formTermsAndServiceError", true);
            break;
        case P14ESignupErrors.MISSING_REQUIRED_FIELDS:
            this._showError("formMissingFieldsError");
            let inputs = this.form.querySelectorAll("input");
            inputs.forEach(function (field) {
                if (!field.value) {
                    field.parentElement.className += " error";
                }
            }.bind(this));
            break;
        case P14ESignupErrors.INVALID_PASSWORD_REPEATED_CHARS:
            this._showError("formPasswordRepeatedChars", true);
            break;
        case P14ESignupErrors.INVALID_PASSWORD_NON_UNIQUE_CHARS:
            this._showError("formPasswordNonUniqueChars", true);
            break;
        case P14ESignupErrors.INVALID_PASSWORD_LENGTH:
            this._showError("formPasswordLength", true);
            break;
        case P14ESignupErrors.INVALID_PASSWORD_NO_DIGITS:
            this._showError("formPasswordNoDigits", true);
            break;
        case P14ESignupErrors.INVALID_PASSWORD_NO_LOWERCASE:
            this._showError("formPasswordNoLowercase", true);
            break;
        case P14ESignupErrors.INVALID_PASSWORD_NO_UPPER_CASE:
            this._showError("formPasswordNoUppercase", true);
            break;
        case P14ESignupErrors.INVALID_PASSWORD_NO_SPECIAL_CHARS:
            this._showError("formPasswordNoSpecialChars", true);
            break;
        default:
            this._showError("formGeneralError");
            break;
    }
}
P14ETrialSignup.prototype._clearAllSignUpErrors = function () {
    this._hideError("formFirstNameError", true);
    this._hideError("formLastNameError", true);
    this._hideError("formEmailError", true);
    this._hideError("formPhoneError", true);
    this._hideError("formCompanyError", true);
    this._hideError("formCaptchaError", true);
    this._hideError("formStateError", true);
    this._hideError("formTermsAndServiceError", true);
    this._hideError("formMissingFieldsError");
    this._hideError("formGeneralError");

    // TODO - uncomment for open enrollment
    // this._hideError("formPasswordRepeatedChars", true);
    // this._hideError("formPasswordNonUniqueChars", true);
    // this._hideError("formPasswordLength", true);
    // this._hideError("formPasswordNoDigits", true);
    // this._hideError("formPasswordNoLowercase", true);
    // this._hideError("formPasswordNoUppercase", true);
    // this._hideError("formPasswordNoSpecialChars", true);
}

P14ETrialSignup.prototype._showError = function (errorElementKey, markParentError) {
    let element = this[errorElementKey];

    element.className = element.className.replace(new RegExp("hide", "g"), "");

    if (markParentError) {
        element.parentElement.className += " error";
    }
}
P14ETrialSignup.prototype._hideError = function (errorElementKey, unmarkParentError) {
    let element = this[errorElementKey];

    element.className += " hide";

    if (unmarkParentError) {
        element.parentElement.className = element.parentElement.className.replace(new RegExp("error", "g"), "");
    }
}
P14ETrialSignup.prototype._showElement = function (elementKey) {
    let element = this[elementKey];
    element.className = element.className.replace(new RegExp("hide", "g"), "");
}
P14ETrialSignup.prototype._hideElement = function (elementKey) {
    let element = this[elementKey];
    element.className += " hide";
}
P14ETrialSignup.prototype._disableSignUpSubmit = function () {
    this.form.submitButton.disabled = true;
}
P14ETrialSignup.prototype._enableSignUpSubmit = function () {
    this.form.submitButton.disabled = false;
}
P14ETrialSignup.prototype._showExistingAccount = function () {
    this._showElement("existingAccountContainer");
    document.body.className += " p14e-trial__modal--open";
}
P14ETrialSignup.prototype._hideExistingAccount = function () {
    this._hideElement("existingAccountContainer");
    document.body.className = document.body.className.replace(new RegExp("p14e-trial__modal--open", "g"), "");
}
P14ETrialSignup.prototype._showVerification = function (firstName, email) {
    // Replace {firstName} and {email} placeholders w/ values
    this.verificationContainer.innerHTML = this.verificationContainer.innerHTML.replace(new RegExp("{firstName}", "g"), firstName).replace(new RegExp("{email}", "g"), "<span class='p14e-trial__email-address'>" + email + "</span>");
    this._showElement("verificationContainer");
}
P14ETrialSignup.prototype._showCreateSuccess = function (firstName, email) {
    // TODO - uncomment for open enrollment
    // this.accountCreateEmail.textContent = email;
    // this.accountLoginLink.href = loginURL;
    // this.accountLoginLink.textContent = loginURL;

    // Replace {firstName} placeholders w/ values
    this.accountCreateSuccess.innerHTML = this.accountCreateSuccess.innerHTML.replace(new RegExp("{firstName}", "g"), firstName);
    this._showElement("accountCreateSuccess");
    this._hideElement("accountCreateHeadline");
}

function getUrlParameter(name) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

window.P14ETrialSignup = P14ETrialSignup;   // Expose to be accessible globally